import React, { useState } from "react";
// import Header from "../Layout/Header";
// import Sidebar from "../Layout/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import BASE_URL from "../config/baseUrl";
import axios from "axios";
export default function MegaTestSubject_add() {
  let navigate = useNavigate();
  let [formval, setFormval] = useState({
    title: "",
    image: "",
    subjectSequence:""
  });
  let [img, setImg] = useState("");
  
  const handelOnChange = (e) => {
    if (e.target.name == "image") {
      const reader = new FileReader();
      reader.onload = () => {
        setImg(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
      setFormval((currVal) => ({ ...currVal, image: e.target.files[0] }));
    } else {
      setFormval((currVal) => ({ ...currVal, [e.target.name]: e.target.value }));
    }
  };

  const handelOnSubmit = (e) => {
    // console.log(formval)
    e.preventDefault();
    // return
    if (
      !formval.title ||
      !formval.image ||
      !formval.subjectSequence
    ) {
      alert("all filed is required");
      return;
    }
    const postData = new FormData();
    postData.append("title", formval.title);
    postData.append("image", formval.image);
    postData.append("sequence", formval.subjectSequence);
    // postData.append("isActive", formval.isActive);
    
    const apiCall = async () => {
      await axios(`${BASE_URL}/api/megatestsubject/add`, {
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        data: postData,
      });
      navigate("/megatestsubject_list");
    };
    apiCall();
  };
  return (
    <>
      {/* <Header /> */}
      {/* <Sidebar /> */}
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <Link to="/megatestsubject_list"><i className="fa-solid fa-circle-arrow-left pe-2"></i></Link>/{"  "}
                <i className="fas fa-book me-2" />
                Add Mega Test Subject
              </h4>
            </div>
            <div className="custom-bredcump"> 
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/megatestsubject_list">Mega Test  Subject Management</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                  Add Mega Test  Subject
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards cstm-form">
              <form action="" onSubmit={handelOnSubmit}>
                <div className="row">
                  <div className="col-lg-4 mb-4">
                    <label htmlFor="" className="form-label">
                      Subject Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      placeholder="Subject Name "
                      onChange={handelOnChange}
                      value={formval.title}
                    />
                  </div>
                  
                  <div className="col-lg-4 mb-4">
                    <label
                      htmlFor=""
                      className="form-label"
                    >
                      Image
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      name="image"
                      onChange={handelOnChange}
                    />
                     {img&& <img src={`${img}`} alt="Preview Image" className='img-50' />}
                  </div>
                  <div className="col-lg-4 mb-4">
                    <label htmlFor="" className="form-label">
                      Subject Sequence
                    </label>
                    <input
                      type="number"
                      name="subjectSequence"
                      onChange={handelOnChange}
                      value={formval.subjectSequence}
                      className="form-control"
                      placeholder="Subject Sequence"
                      min={0}
                    />
                  </div>
                  <div className="col-lg-12 mb-4 text-center">
                    <button className="thm-btn rounded-2" type="submit">
                    Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
