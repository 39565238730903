import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BASE_URL from "../config/baseUrl";
import edit from "../../assets/images/edit.png";
import trash from "../../assets/images/trash.png";
import Swal from "sweetalert2";
const MegaTestList = () => {
  const [megaTest, setMegaTest] = useState([]);
  const [updateSequence, setUpdateSequence] = useState();
  const [mtestStatus, setMtestStatus] = useState([]);
  const [searchTest, setSearchTest] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  useEffect(() => {
    if (isSearching) {
      handleMegaTestSearch();
    } else {
      fetchData();
    }
  }, [isSearching]);
  const fetchData = async () => {
    try {
      const response = await axios(`${BASE_URL}/api/megatest/alldata `, {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      // console.log("responsemtest", response);
      const megaTestRes = response.data.data
       const sortedMegaTest = megaTestRes.sort((a,b)=>a.sequence - b.sequence)
      setMegaTest(sortedMegaTest);
      const initialStatus = megaTestRes.map((mtest) => ({
        id: mtest._id,
        status: mtest.isActive,
      }));
      //   console.log("initialstatus", initialStatus);
      setMtestStatus(initialStatus);
    } catch (error) {
      console.log("fetching megaTest error", error);
    }
  };
  //update Sequence
  const handleSequenceUpdate = async (id) => {
    try {
      const response = await axios.put(
        `${BASE_URL}/api/megatest/update_sequence/${id}`,
        { sequence: updateSequence },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      //   console.log("response",response.data.data)
      //   fetchData();
      setMegaTest(response.data.data);
    } catch (error) {
      console.log("sequence updating error", error);
    }
  };

  // toggle status
  const handleStatus = async (id) => {
    try {
      const response = await axios.put(
        `${BASE_URL}/api/megatest/active/${id}`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      //   console.log("aaya", response.data.data);
      const updatedStatus = mtestStatus.map((mtest) =>
        mtest.id === id
          ? { ...mtest, status: response.data.data.isActive }
          : mtest
      );
      setMtestStatus(updatedStatus);
    } catch (error) {
      console.log("updating status error", error);
    }
  };

  // search test
  const handleMegaTestSearch = async () => {
    try {
      const response = await axios(
        `${BASE_URL}/api/megatest/search?title=${searchTest}`,
        {
          method: "GET",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      // console.log("responsesearch", response);
      setMegaTest(response.data.data);

      // setIsSearching(false);
    } catch (error) {
      console.log("searching test error", error);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setIsSearching(true);
  };

   // handle Reset Search
   const handleResetSearch = () => {
    setSearchTest("");
    setIsSearching(false);
  };


  const handleDelete = async (id) => {
    // Show confirmation dialog
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });
  
    // If confirmed, proceed with deletion
    if (result.isConfirmed) {
      try {
        // Make the DELETE request
        const delResponse = await axios.post(
          `${BASE_URL}/api/megatest/delete/${id}`,
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        );
        // console.log("delResponse",delResponse)
        // Update state
        const deletedUser = delResponse.data;
        // console.log("deletedUSer",deletedUser)
        setMegaTest(megaTest.filter((mtest) => mtest._id !== deletedUser._id));
        // Show success message
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
        });
        
        // Refresh the data
        fetchData();
        
      } catch (error) {
        console.error("Error deleting mega test:", error);
        Swal.fire({
          title: "Error!",
          text: "There was a problem deleting the mega test.",
          icon: "error",
        });
      }
    }
  };
  return (
    <>
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fa-brands fa-readme  me-2" />
                Mega Test List
              </h4>
              <Link className="blu-btn" to="/megatestadd">
                Add Mega Test
              </Link>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Mega Test List
                  </li>
                </ol>
              </nav>
            </div>
          </div>

          <div className="col-lg-12">
            <div className="cards bus-list">
              <div className="bus-filter">
                <div className="row">
                  <div className="col-lg-7">
                    <h4 className="table-head">
                      Test List ({megaTest.length})
                    </h4>
                  </div>
                  <div className="col-lg-5">
                    <form action="">
                      <div className="row justify-content-end gx-2 align-items-center">
                        <div className="col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search Test..."
                            value={searchTest}
                            onChange={(e) => setSearchTest(e.target.value)}
                          />
                        </div>
                        <div className="col-lg-4">
                          <button type="button"
                          onClick={handleSearch}
                          className="blu-btn me-2">
                            Search
                          </button>
                          <button
                            type="button"
                            className="blu-btn"
                            onClick={handleResetSearch}
                          >
                            Reset Search
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="table table-responsive custom-table">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th>SR NO.</th>
                      <th>Title</th>
                      <th>Sequence</th>
                      <th>Status</th>
                      <th>duration</th>
                      <th>marks</th>
                      <th>negative Marks</th>
                      <th>total Question </th>
                      <th>ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {megaTest?.map((arr, i) => (
                      <tr key={arr?._id}>
                        <td>{i + 1}</td>
                        <td>{arr?.title}</td>
                        <td>
                          <div className="d-flex table-box">
                            <input
                              type="number"
                              className="form-control"
                              defaultValue={arr?.sequence}
                              onChange={(e) =>
                                setUpdateSequence(e.target.value)
                              }
                              min={0}
                            />
                            <button
                              className="btn btn-success ms-2"
                              onClick={() => handleSequenceUpdate(arr._id)}
                            >
                              Update
                            </button>
                          </div>
                        </td>
                        <td>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultChecked={arr?.isActive}
                              checked={
                                mtestStatus.find(
                                  (mtest) => mtest.id === arr._id
                                ).status
                              }
                              onClick={() => handleStatus(arr._id)}
                            />
                          </div>
                        </td>
                        <td>{arr?.duration}</td>
                        <td>{arr?.marks}</td>
                        <td>{arr?.negMarks}</td>
                        <td>{arr?.totalQuestions}</td>
                        <td>
                          <Link
                            className="mx-1"
                            to={`/megatestedit/${arr?._id}`}
                          >
                            <img src={edit} alt="" />
                          </Link>
                          <Link
                            className="mx-1"
                            onClick={() => handleDelete(arr?._id)}
                          >
                            <img src={trash} alt="" />
                          </Link>
                        </td>
                        <td>
                          <Link to={`/attent_test_list/${arr._id}`} className="blu-btn w-100">
                            result
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* {showPagination && (
                  <div className="custom-pagination">
                    <ul className="pagination">
                      <li className="page-item">
                        <button
                          className="page-link"
                          to=""
                          aria-label="Previous"
                          disabled={pageNumber === 1 && true}
                          onClick={() => setPageNumber((prev) => prev - 1)}
                        >
                          <span aria-hidden="true">«</span>
                        </button>
                      </li>
                      {pageNumber >= 2 && (
                        <li className="page-item">
                          <Link className="page-link" to="">
                            {pageNumber - 1}
                          </Link>
                        </li>
                      )}
                      <li className="page-item">
                        <Link className="page-link active" to="">
                          {pageNumber}
                        </Link>
                      </li>
                      <li className="page-item">
                        <Link
                          className="page-link"
                          to=""
                          onClick={() => setPageNumber((prev) => prev + 1)}
                        >
                          {pageNumber + 1}
                        </Link>
                      </li>
                      <li className="page-item">
                        <Link
                          className="page-link"
                          to=""
                          onClick={() => setPageNumber((prev) => prev + 2)}
                        >
                          {pageNumber + 2}
                        </Link>
                      </li>
                      <li className="page-item">
                        <Link
                          className="page-link"
                          to=""
                          aria-label="Next"
                          onClick={() => setPageNumber((prev) => prev + 1)}
                        >
                          <span aria-hidden="true">»</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MegaTestList;
