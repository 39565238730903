import React, { useState } from "react";
// import Header from "../Layout/Header";
// import Sidebar from "../Layout/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import BASE_URL from "../config/baseUrl";
import axios from "axios";
export default function LiveVideoCourseCategory_add() {
  let navigate = useNavigate();
  let [formval, setFormval] = useState({
    category: "",
    categoryPhoto: "",
    categorySequence: "",
    isActive: true
  });
  let [img, setImg] = useState("");
  // const handelOnChange = (e) => {
  //   if (e.target.name === "categoryPhoto") {
  //     setFormval((currVal) => ({
  //       ...currVal,
  //       categoryPhoto: e.target.files[0],
  //     }));
  //   } else {
  //     setFormval((currVal) => ({
  //       ...currVal,
  //       [e.target.name]: e.target.value,
  //     }));
  //   }
  // };



  const handelOnChange = (e) => {
    if (e.target.name == "categoryPhoto") {
      const reader = new FileReader();
      reader.onload = () => {
        setImg(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
      setFormval((currVal) => ({ ...currVal, categoryPhoto: e.target.files[0] }));
    } else {
      setFormval((currVal) => ({ ...currVal, [e.target.name]: e.target.value }));
    }
  };

  const handelOnSubmit = (e) => {
    console.log(formval)
    e.preventDefault();
    if (
      !formval.category ||
      !formval.categoryPhoto ||
      !formval.categorySequence
    ) {
      alert("all filed is required");
      return;
    }

    const postData = new FormData();
    postData.append("categoryPhoto", formval.categoryPhoto);
    postData.append("category", formval.category);
    postData.append("categorySequence", formval.categorySequence);
    postData.append("isActive", formval.isActive);

    const apiCall = async () => {
      let response = await axios(`${BASE_URL}/api/livevideocoursescategory/add`, {
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        data: postData,
      });
      // console.log(response);
      navigate("/live_video_course_category_list");
    };
    apiCall();
  };
  return (
    <>
      {/* <Header /> */}
      {/* <Sidebar /> */}
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <Link to="/live_video_course_category_list"><i className="fa-solid fa-circle-arrow-left pe-2"></i></Link>/{"  "}
                <i className="fas fa-video me-2" />
                Add Live Video Course Category
              </h4>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/live_video_course_list">Live Video Course Management</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Add Live Video Course Category
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards cstm-form">
              <form action="" onSubmit={handelOnSubmit}>
                <div className="row">
                  <div className="col-lg-4 mb-4">
                    <label htmlFor="" className="form-label">
                      Category Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="category"
                      onChange={handelOnChange}
                      value={formval.category}
                    />
                  </div>
                  <div className="col-lg-4 mb-4">
                    <label htmlFor="" className="form-label">
                      Category Sequence
                    </label>
                    <input
                      type="number"
                      name="categorySequence"
                      onChange={handelOnChange}
                      value={formval.categorySequence}
                      className="form-control"
                      min={0}
                    />
                  </div>
                  <div className="col-lg-4 mb-4">
                    <label htmlFor="" className="form-label">
                      Status
                    </label>
                    <select name="isActive" onChange={handelOnChange} className="form-select">
                      <option defaultValue="">Select Status</option>
                      <option value={true}>Active</option>
                      <option value={false}>Inactive</option>
                    </select>
                  </div>
                  <div className="col-lg-4 mb-4">
                    <label
                      htmlFor=""
                      className="form-label"
                    >
                      Image
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      name="categoryPhoto"
                      onChange={handelOnChange}

                    />
                    {img && <img src={`${img}`} alt="Preview Image" className='img-50' />}
                  </div>
                  <div className="col-lg-12 mb-4 text-center">
                    <button className="thm-btn rounded-2" type="submit">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
