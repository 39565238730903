import React from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from 'quill-image-resize-module-react';

// Use a specific version of Quill's Parchment
// const Parchment = Quill.import('parchment');

// Register the module with correct configuration
Quill.register('modules/imageResize', ImageResize);
export default function React_Quill_Editor({
  disabled,
  data,
  seteditor,
  editorTitle,
  fieldName,
}) {
  let modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ 'color': [] }, { 'background': [] }],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize']
    }
  };
  return (
    <>

      <div>{editorTitle} </div>
      <ReactQuill theme="snow" readOnly={disabled}
        className="react-quill"
        modules={modules}
        value={data}
        onChange={(e) => (seteditor((curval) => ({ ...curval, [fieldName]: e })))} />
    </>
  );
}


// import React, { useRef, useEffect, useState } from "react";
// import ReactQuill, { Quill } from "react-quill";
// import "react-quill/dist/quill.snow.css";
// import ImageResize from "quill-image-resize-module-react";

// // Register Image Resize Module
// Quill.register("modules/imageResize", ImageResize);

// export default function React_Quill_Editor({
//   disabled,
//   data,
//   seteditor,
//   editorTitle,
//   fieldName,
// }) {
//   const quillRef = useRef(null);
//   const [editorHtml, setEditorHtml] = useState(data || "");

//   // **Ensure Quill instance is initialized properly**
//   useEffect(() => {
//     if (quillRef.current) {
//       const quillInstance = quillRef.current.getEditor();
//       if (quillInstance) {
//         console.log("Quill Initialized Successfully");
//       }
//     }
//   }, []);

//   // **Custom Image Handler**
//   const imageHandler = () => {
//     const quill = quillRef.current?.getEditor(); // Get Quill instance safely
//     if (!quill) return;

//     const imageUrl = prompt("Enter image URL:"); // Ask user for an image URL
//     if (imageUrl) {
//       const range = quill.getSelection(); // Get cursor position
//       quill.insertEmbed(range?.index || 0, "image", imageUrl); // Insert image

//       // 🔥 Ensure the editor state is updated properly
//       setEditorHtml(quill.root.innerHTML); // Update local state
//       seteditor((curVal) => ({
//         ...curVal,
//         [fieldName]: quill.root.innerHTML, // Update parent state
//       }));
//     }
//   };

//   // **Quill Modules Configuration**
//   let modules = {
//     toolbar: {
//       container: [
//         [{ header: "1" }, { header: "2" }, { font: [] }],
//         [{ size: [] }],
//         ["bold", "italic", "underline", "strike", "blockquote"],
//         [{ color: [] }, { background: [] }],
//         [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
//         ["link", "image", "video"],
//         ["clean"],
//       ],
//       handlers: {
//         image: imageHandler, // Custom image handler
//       },
//     },
//     clipboard: {
//       matchVisual: false,
//     },
//     imageResize: {
//       parchment: Quill.import("parchment"),
//       modules: ["Resize", "DisplaySize"],
//     },
//   };

//   return (
//     <>
//       <div>{editorTitle}</div>
//       <ReactQuill
//         ref={quillRef}
//         theme="snow"
//         readOnly={disabled}
//         className="react-quill"
//         modules={modules}
//         value={editorHtml}
//         onChange={(e) => {
//           setEditorHtml(e); // Update local state
//           seteditor((curVal) => ({ ...curVal, [fieldName]: e })); // Update parent state
//         }}
//       />
//     </>
//   );
// }
