import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import edit from "../../assets/images/edit.png";
import trash from "../../assets/images/trash.png";
import BASE_URL from "../config/baseUrl";
import { toast } from "react-toastify";

import { io } from 'socket.io-client';

const CommentList = () => {
  const { tid } = useParams();
  // console.log("tid", tid);
  const [commentData, setCommentData] = useState([]);
  const [replyText, setReplyText] = useState("");
  const [replyCommentId, setReplyCommentId] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);



  // let url = "http://localhost:1100";
  // let url = "http://qednode.objectsquare.in";
  // let url = "http://216.48.182.23:1100";
  // let url = "https://qualitysocket.objectsquare.in";
  // let url = "http://216.48.182.23:1100/";
  // let url = "https://qualitysocket.objectsquare.in:1100";


  // let url = "https://qednode.objectsquare.in";
 
  useEffect(() => {
    const newSocket = io(BASE_URL);   
    console.log(newSocket);

    newSocket.on('reply', (message) => {
      console.log('Received message:', message);
      setCommentData((curVal)=>(curVal.map((arr) => (
        arr._id == message._id ? { ...arr ,  reply: message.reply } : arr
      ))
        
      ))
    // console.log(commentData);
    
    //   let data = commentData.map((arr) => (
    //       arr._id == message._id ? { ...arr ,  reply: message.reply } : arr
    //     ));
    //     console.log(data);
        // setCommentData(data)
    });
    newSocket.on('add Comment', (message) => {
      console.log('Received message:', message);
      setCommentData((curVal)=>(
        [...curVal,message]
      ))
    });

 
    return () => {
      newSocket.disconnect();
    };
  }, [BASE_URL]);






  const fetchData = async () => {
    const response = await axios.get(
      `${BASE_URL}/api/videoComment/getcomment?topic_id=${tid}`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
     
    setCommentData(response.data.data);
  };

  const handleDelete = async (id) => {
    // console.log("id",id)
    const delResponse = await axios.delete(
      `${BASE_URL}/api/videoComment/deletecomment/${id}`
    );
    // console.log("response1111111", delResponse);
    fetchData();
  };
  /// reply comment
  const handleCommentReply = async () => {
    // console.log("replyid", id);
    if(!replyText){
        return toast.error("empty reply not allowed")
    }
    try {
      const resp = await axios.post(
        `${BASE_URL}/api/videoComment/sendReply/${replyCommentId}`,
        { reply: replyText },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      setReplyText("");
      setReplyCommentId(null);
      fetchData();
      let closeModelBtn = document.getElementById("closemodel")
      closeModelBtn.click()
      toast.success(resp.data.message)
    } catch (error) {
      console.log("Failed to reply error", error);
    }
  };

  // http://216.48.184.213:8100/api/freevideopdf/searchfreevideo- data all free  pdf
  // http://216.48.184.213:8100/api/freevideopdf/searchfreevideo?subject_id=6697784447314ccb512c0b5d - data by pdf subject
  // http://216.48.184.213:8100/api/freevideopdf/searchfreepdf - data all free  Video
  // http://216.48.184.213:8100/api/freevideopdf/searchfreepdf?subject_id=6697784447314ccb512c0b5d -  data by video subject

  return (
    <>
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="far fa-chart-bar me-2" />
                Comment List
              </h4>
              <Link to="/commentadd" className="blu-btn">
                <i className="far fa-plus me-2" />
                Add Comment
              </Link>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Comment
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards bus-list">
              <div className="bus-filter">
                <div className="row justify-content-start">
                  <div className="col-lg-7">
                    <h4 className="table-head">
                      Total Comment ({commentData.length}){" "}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="table table-responsive custom-table">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th>SR NO.</th>
                      <th>Comment Name</th>
                      <th>Sender Id</th>
                      <th>Reply</th>
                      <th>Action</th>
                      {/* <th>Reply Action</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {commentData.length ? commentData?.map((curElem, index) => {
                      return (
                        <tr key={curElem._id}>
                          <td>{index + 1}</td>
                          <td>{curElem.comment}</td>
                          <td>{curElem.senderID?.first_name}</td>
                          <td className="d-flex">
                            {curElem.reply}
                            {curElem.reply ? null : (
                              <div className="container">
                                <a
                                  href="#"
                                  className="btn btn-danger"
                                  data-bs-toggle="modal"
                                  data-bs-target="#replay-popup"
                                  onClick={() => {
                                    setReplyCommentId(curElem._id);
                                  }}
                                >
                                  Reply
                                </a>
                              </div>
                            )}
                            <div
                              className="modal fade"
                              id="replay-popup"
                              tabIndex="-1"
                              aria-labelledby="exampleModalLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5
                                      className="modal-title"
                                      id="exampleModalLabel"
                                    >
                                      Reply Comment
                                    </h5>
                                    <button
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                      id="closemodel"
                                    ></button>
                                  </div>
                                  <div className="modal-body">
                                    {" "}
                                    <textarea
                                      className="form-control"
                                      rows="4"
                                      value={replyText}
                                      onChange={(e) =>
                                        setReplyText(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="modal-footer">
                                    <button
                                      type="button"
                                      className="btn btn-secondary"
                                      data-bs-dismiss="modal"
                                    >
                                      Close
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      onClick={handleCommentReply}
                                      // className="btn btn-secondary"
                                      // data-bs-dismiss="modal"
                                    >
                                      Reply Submit
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            {/* <Link
                                to={`/editbanner/${curElem._id}`}
                                className="mx-2"
                              >
                                <img src={edit} alt="" />
                              </Link> */}
                            <button className="mx-2">
                              <img
                                src={trash}
                                alt="delete"
                                onClick={() => handleDelete(curElem._id)}
                              />
                            </button>
                          </td>
                          {/* <td>
                            {
                              curElem.reply ? null : (
                  
                            <div className="container">
                              <a
                                href="#"
                                className="btn btn-danger"
                                data-bs-toggle="modal"
                                data-bs-target="#replay-popup"
                                onClick={() => {
                                  setReplyCommentId(curElem._id);
                                }}
                              >
                                Reply
                              </a>
                            </div>  )}
                            <div
                              className="modal fade"
                              id="replay-popup"
                              tabIndex="-1"
                              aria-labelledby="exampleModalLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5
                                      className="modal-title"
                                      id="exampleModalLabel"
                                    >
                                      Modal title
                                    </h5>
                                    <button
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                  <div className="modal-body">
                                    {" "}
                                    <textarea
                                      className="form-control"
                                      rows="4"
                                      value={replyText}
                                      onChange={(e) =>
                                        setReplyText(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="modal-footer">
                                    <button
                                      type="button"
                                      className="btn btn-secondary"
                                      data-bs-dismiss="modal"
                                    >
                                      Close
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      onClick={handleCommentReply}
                                    >
                                      Reply Submit
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td> */}
                        </tr>
                      );
                    }): <tr> <td> <div className="p-3 rounded bg-white shadow"> <p className="text-center text-black">No Commnets Found.!</p> </div></td></tr> }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CommentList;
