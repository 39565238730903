import React, { useState,useEffect } from "react";
// import Header from "../Layout/Header";
// import Sidebar from "../Layout/Sidebar";
import { Link, useNavigate } from "react-router-dom";
// import MyCkeditor from '../Video_Courses/MyCKeditor'
import BASE_URL from "../config/baseUrl";
import axios from "axios";
// import Avtar from "../../assets/images/avtar.jpg";
// import MyCkeditor from "../Video_Courses/MyCKeditor2";
import MyCkeditor from "../ckeditor/MyCKeditor";
import Tinymce from "../ckeditor/Tinymce";
import RTEEditor from "../ckeditor/RTEEditor";
export default function LiveVideoCourse_add() {
  let navigate = useNavigate();
  let [formval, setFormval] = useState({
    title: "",
    livevideoCategory: "",
    images: [],
    details: "",
    price: "",
    offerPrice: "",
    isActive: true,
    sequence:''
  });
  let [categoryData, setCategoryData] = useState([]);
  const [image, setImages] = useState([]);

  // const handelOnChange = (e) => {
  //   if (e.target.name === "images") {
  //     let arr = [];
  //     for (let i of e.target.files) {
  //       arr.push(i);
  //     }
  //     setFormval((currVal) => ({ ...currVal, images: arr }));
  //   } else {
  //     setFormval((currVal) => ({
  //       ...currVal,
  //       [e.target.name]: e.target.value,
  //     }));
  //   }
  // };

  const handelOnChange = (e) => {
    if (e.target.name == "images") {
      let arr = []
      let imagesArray = []
      for (let i of e.target.files) {
        arr.push(i)
        const reader = new FileReader();
        reader.onload = (event) => {
          imagesArray.push(event.target.result);
          if (imagesArray.length === (e.target.files).length) {
            // setImages(imagesArray);
            setImages((prv)=>{
              return [...prv,...imagesArray] 
            });
          }
        };
        reader.readAsDataURL(i);
      }
      setFormval((currVal) => ({ ...currVal, images:[...formval.images,...arr] }));
    } else {
      setFormval((currVal) => ({ ...currVal, [e.target.name]: e.target.value }));
    }
  };

  useEffect(() => {
    const apiCall = async () => {
      let response = await axios(
        `${BASE_URL}/api/livevideocoursescategory/allcourses`,
        {
          method: "GET",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      let filterData= response?.data?.data
      filterData=response?.data?.data.filter((arr)=>arr.isActive ===  true)
      setCategoryData(filterData);
    };
    apiCall();
  }, []);

  const handelOnSubmit = (e) => {
    e.preventDefault();
    if (image.length < 4) {
      e.target.value = ''; // Clear the input field
      return alert('You can add minimum select up to 4 images.');
    }
    if (image.length > 10) {
      e.target.value = ''; // Clear the input field
      return alert('You can select max to 10 images.');
    }

    if (
      !formval.title ||
      !formval.details ||
      !formval.livevideoCategory ||
      !formval.price ||
      !formval.offerPrice ||
      !formval.sequence
    ) {
      alert("please fill all field");
      return;
    }

    const postData = new FormData();
    formval?.images?.map((arr) => {
      postData.append("images", arr);
    });
    postData.append("title", formval.title);
    postData.append("livevideoCategory", formval.livevideoCategory);
    postData.append("details", formval.details);
    postData.append("price", formval.price);
    postData.append("offerPrice", formval.offerPrice);
    postData.append("isActive", formval.isActive);
    postData.append("sequence", formval.sequence);

    const apiCall = async () => {
      let response = await axios(`${BASE_URL}/api/livevideocourse/add`, {
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        data: postData,
      });
      console.log(response)
      if((response?.data?.status)){
        alert("some error accoured");
        return
      }
      navigate("/livevideocourselist");
    };
    apiCall();
  };

  let handleImgDelete=(e)=>{
    setImages( image.filter((item, index) => index !== e));
    let array = formval.images.filter((item, index) => index !== e);
    setFormval((currVal) => ({ ...currVal, images: array }));
   }
  
  return (
    <>
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <Link to="/livevideocourselist"><i className="fa-solid fa-circle-arrow-left pe-2"></i></Link>/{"  "}
                <i className="fas fa-video me-2" />
                Add Live Video Course
              </h4>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/livevideocourselist">live Video Course Management</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Add Live Video Course
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards cstm-form">
              <form action="" onSubmit={handelOnSubmit}>
                <div className="row">
                  <div className="col-lg-4 mb-4">
                    <label htmlFor="" className="form-label">
                      Video Title
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      onChange={handelOnChange}
                      value={formval.title}
                    />
                  </div>
                  <div className="col-lg-4 mb-4">
                    <label htmlFor="" className="form-label">
                      Video Category
                    </label>
                    <select
                      name="livevideoCategory"
                      onChange={handelOnChange}
                      className="form-select"
                    >
                      <option defaultValue={""} >Select Category</option>
                        {categoryData?.map((arr) => (
                        <option key={arr?._id} value={arr._id}>
                          {arr?.category}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-lg-4 mb-4">
                    <label htmlFor="" className="form-label">
                      Live Video Sequence
                    </label>
                    <input type="number" className="form-control"  name='sequence'
                      onChange={handelOnChange} min={0}
                      value={formval.sequence}/>
                  </div>
                  <div className="col-lg-4 mb-4">
                    <label htmlFor="" className="form-label">
                      Status
                    </label>
                    <select
                      className="form-select"
                      name="isActive"
                      onChange={handelOnChange}
                    >
                      <option defaultValue={""} >Select Status</option>
                      <option value={true}>Active</option>
                      <option value={false}>Inactive</option>
                    </select>
                  </div>
                  <div className="col-lg-4 mb-4">
                    <label htmlFor="" className="form-label">
                      Price
                    </label>
                    <input
                      type="number"
                      name="price"
                      placeholder="Price"
                      onChange={handelOnChange}
                      value={formval?.price}
                      className="form-control"
                      min={0}
                    />
                  </div>
                  <div className="col-lg-4 mb-4">
                    <label htmlFor="" className="form-label">
                      Offer Price
                    </label>
                    <input
                      type="number"
                      name="offerPrice"
                      placeholder="Offer Price"
                      onChange={handelOnChange}
                      value={formval?.offerPrice}
                      className="form-control"
                      min={0}
                    />
                  </div>
                  <div className="col-lg-4 mb-4">
                    <label htmlFor="" className="form-label">
                      Image
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      defaultValue=""
                      name="images"
                      onChange={handelOnChange}
                      multiple
                    />
                    <div className='image-box'>
                     {image.map((imageUrl, index) => (
                            <div className='image-box-innr' key={index}>
                              <img  src={imageUrl} alt={`Image ${index}`} />
                              <Link
                              to="#"
                              onClick={() => handleImgDelete(index)}
                            >
                              <i className="fa fa-times" />
                            </Link>
                            </div>
                          ))}
                     </div>
                  </div>
                  <div className="col-lg-12 mb-4">
                    <div id="editor">
                      {/* <Tinymce
                        disabled={false}
                        data={formval?.details}
                        seteditor={setFormval}
                        editorTitle={"Video Details"}
                        fieldName={"details"}
                      /> */}
                      <RTEEditor
                        value={formval?.details || ''}
                        markup=""
                        onChange={(htmlContent) => {
                          setFormval(prev => ({
                            ...prev,
                            details: htmlContent
                          }));
                        }}
                        editorTitle="Video Details"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 mb-4 text-center">
                    <button className="thm-btn" type="submit">
                    Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
