import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import React_Quill_Editor from "../ckeditor/React_Quill_Editor";
import BASE_URL from "../config/baseUrl";
import axios from "axios";
import RTEEditor from "../ckeditor/RTEEditor";
function MegaQuestionEdit() {
  let navigate = useNavigate();
  const [subject, setSubject] = useState([]);
  const { id } = useParams();

  const [formval, setFormval] = useState({});

  const handelOnChange = (e) => {
    const { name, value } = e.target;
    setFormval((currVal) => ({ ...currVal, [name]: value }));
  };

  useEffect(() => {
    fetchfordata();
    fetchData();
  }, []);
  const fetchData = async () => {
    let response = await axios(`${BASE_URL}/api/megatestsubject/alldata`, {
      method: "GET",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
    //   console.log(response.data.data);
    setSubject(response?.data?.data);
  };

  const fetchfordata = async () => {
    let response = await axios(`${BASE_URL}/api/megatestquestion/view/${id}`, {
      method: "GET",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
    // console.log(response.data.data);
    setFormval(response?.data?.data);
  };


  const handelOnSubmit = (e) => {
    e.preventDefault();
    if (
      !formval.question ||
      !formval.option_a ||
      !formval.option_b ||
      !formval.option_c ||
      !formval.option_d ||
      !formval.answer ||
      !formval.isActive ||
      !formval.solution ||
      !formval.subject_id
    ) {
      alert("please fill all field");
      return;
    }

    const apiCall = async () => {
      let response = await axios(`${BASE_URL}/api/megatestquestion/edit/${id}`, {
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        data: formval,
      });
      if (!response?.data?.status) {
        alert("some error accoured");
        return;
      }
      navigate("/megaquestionlist");
    };
    apiCall();
  };
  return (
    <>
      <section className="main-sec question-page">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <Link to="/megaquestionlist"><i className="fa-duotone fa-solid fa-circle-arrow-left"></i></Link>{" "}/{" "}
                <i className="fa-solid fa-book  me-2" />
                Edit Question
              </h4>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/test_list">Mega Test</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Edit Question
                  </li>
                </ol>
              </nav>
            </div>
          </div>

          <div className="col-lg-12">
            <div className="cards cstm-form">
              <form onSubmit={handelOnSubmit}>
                <div className="row">
                  <div className="mb-3 col-lg-4">
                    <label className="form-label">Select Subject</label>
                    <select
                      name="subject_id"
                      onChange={handelOnChange}
                      value={formval.subject_id?._id}
                      className="form-select"
                    >
                      <option>Select Subject</option>
                      {subject.map((arr) => (
                        <option key={arr?._id} value={arr?._id}>
                          {arr?.title}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-3 col-lg-4">
                    <label className="form-label">Select Status</label>
                    <select
                      className="form-select"
                      name="isActive"
                      onChange={handelOnChange}
                      value={formval.isActive}
                    >
                      <option>Select Status</option>
                      <option value={true}>Active</option>
                      <option value={false}>Inactive</option>
                    </select>
                  </div>
                  {/* <div className="mb-3 col-lg-6">
                    <label className="form-label">Question</label>
                    <input
                      type="text"
                      className="form-control"
                      name="question"
                      onChange={handelOnChange}
                      value={formval.question}
                    />
                  </div>
                  <div className="mb-3 col-lg-3">
                    <label className="form-label">Option A</label>
                    <input
                      type="text"
                      className="form-control"
                      name="option_a"
                      onChange={handelOnChange}
                      value={formval.option_a}
                    />
                  </div>
                  <div className="mb-3 col-lg-3">
                    <label className="form-label">Option B</label>
                    <input
                      type="text"
                      className="form-control"
                      name="option_b"
                      onChange={handelOnChange}
                      value={formval.option_b}
                    />
                  </div>
                  <div className="mb-3 col-lg-3">
                    <label className="form-label">Option C</label>
                    <input
                      type="text"
                      className="form-control"
                      name="option_c"
                      onChange={handelOnChange}
                      value={formval.option_c}
                    />
                  </div>
                  <div className="mb-3 col-lg-3">
                    <label className="form-label">Option D</label>
                    <input
                      type="text"
                      className="form-control"
                      name="option_d"
                      onChange={handelOnChange}
                      value={formval.option_d}
                    />
                  </div>
                  <div className="mb-3 col-lg-6">
                    <label className="form-label">Answer</label>
                    <select
                      className="form-select"
                      name="answer"
                      onChange={handelOnChange}
                      value={formval?.answer}
                    >
                      <option>Select Answer</option>
                      <option value="1">Option A</option>
                      <option value="2">Option B</option>
                      <option value="3">Option C</option>
                      <option value="4">Option D</option>
                    </select>
                  </div> */}

                  <div className="mb-3 col-lg-12">
                    {/* <React_Quill_Editor
                      disabled={false}
                      data={formval?.question}
                      seteditor={setFormval}
                      fieldName={"question"}
                      editorTitle={"Question"}
                    /> */}
                    <RTEEditor
                      value={formval?.question}
                      markup=""
                      onChange={(htmlContent) => {
                        setFormval(prev => ({
                          ...prev,
                          question: htmlContent
                        }));
                      }}
                      editorTitle="Question"
                    />
                  </div>
                  <div className="mb-3 col-lg-6">
                    {/* <React_Quill_Editor
                      disabled={false}
                      data={formval?.option_a}
                      seteditor={setFormval}
                      fieldName={"option_a"}
                      editorTitle={"Option A"}
                    /> */}
                    <RTEEditor
                      value={formval?.option_a}
                      markup=""
                      onChange={(htmlContent) => {
                        setFormval(prev => ({
                          ...prev,
                          option_a: htmlContent
                        }));
                      }}
                      editorTitle="Option A"
                    />
                  </div>
                  <div className="mb-3 col-lg-6">
                    {/* <React_Quill_Editor
                      disabled={false}
                      data={formval?.option_b}
                      seteditor={setFormval}
                      fieldName={"option_b"}
                      editorTitle={"Option B"}
                    /> */}
                    <RTEEditor
                      value={formval?.option_b}
                      markup=""
                      onChange={(htmlContent) => {
                        setFormval(prev => ({
                          ...prev,
                          option_b: htmlContent
                        }));
                      }}
                      editorTitle="Option B"
                    />
                  </div>
                  <div className="mb-3 col-lg-6">
                    {/* <React_Quill_Editor
                      disabled={false}
                      data={formval?.option_c}
                      seteditor={setFormval}
                      fieldName={"option_c"}
                      editorTitle={"Option C"}
                    /> */}
                    <RTEEditor
                      value={formval?.option_c}
                      markup=""
                      onChange={(htmlContent) => {
                        setFormval(prev => ({
                          ...prev,
                          option_c: htmlContent
                        }));
                      }}
                      editorTitle="Option C"
                    />
                  </div>
                  <div className="mb-3 col-lg-6">
                    {/* <React_Quill_Editor
                      disabled={false}
                      data={formval?.option_d}
                      seteditor={setFormval}
                      fieldName={"option_d"}
                      editorTitle={"Option D"}
                    /> */}
                    <RTEEditor
                      value={formval?.option_d}
                      markup=""
                      onChange={(htmlContent) => {
                        setFormval(prev => ({
                          ...prev,
                          option_d: htmlContent
                        }));
                      }}
                      editorTitle="Option D"
                    />
                  </div>
                  <div className="mb-3 col-lg-6">
                    <label className="form-label">Answer</label>
                    <select
                      className="form-select"
                      name="answer"
                      onChange={handelOnChange}
                    >
                      <option>Select Answer</option>
                      <option value="1">Option A</option>
                      <option value="2">Option B</option>
                      <option value="3">Option C</option>
                      <option value="4">Option D</option>
                    </select>
                  </div>
                  <div className="mb-3 col-lg-12">
                    {/* <React_Quill_Editor
                      disabled={false}
                      data={formval?.solution}
                      seteditor={setFormval}
                      fieldName={"solution"}
                      editorTitle={"Explanation"}
                    /> */}
                    <RTEEditor
                      value={formval?.solution}
                      markup=""
                      onChange={(htmlContent) => {
                        setFormval(prev => ({
                          ...prev,
                          solution: htmlContent
                        }));
                      }}
                      editorTitle="Explanation"
                    />
                  </div>
                  <div className="mb-3 col-lg-12 text-center">
                    <button type="submit" className="thm-btn w-25">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default MegaQuestionEdit;
