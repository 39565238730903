import React, { useState, useEffect } from "react";
// import Header from "../Layout/Header";
// import Sidebar from "../Layout/Sidebar";
import BASE_URL from "../config/baseUrl";
// import Ras from "../../assets/images/ras.png";
import edit from "../../assets/images/edit.png";
import trash from "../../assets/images/trash.png";
import axios from "axios";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import dummy from "../../assets/images/dummy.png"
export default function FreeSubjectPdfList() {
    let [subject, setSubject] = useState([]);
    let [updateSequence, setUpdateSequence] = useState();
    let [pageNumber, setPageNumber] = useState(1)
    const [showPagination, setShowPagination] = useState(true);
    let [subjectCount, setSubjectCount] = useState([]);
    const [searchTitle, setSearchTitle] = useState("");

    let [showIndex, setShowIndex] = useState(false);

    useEffect(() => {
        let apiCall = async () => {
            let response = await axios(
                // `${BASE_URL}/api/freePdfSubject/alldata?p=${pageNumber}`,
                `${BASE_URL}/api/freePdfSubject/alldata`,
                {
                    method: "GET",
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                }
            );
            console.log(response.data)
            if (!(response?.data?.status) && response.status === 200) {
                setPageNumber(pageNumber - 1)
                return alert("Page Data Not Found ")
            }
            // const subData = response?.data?.data?.allSubjectData
            // const newSubData = subData.sort((a, b) => a.sequence - b.sequence)
            setSubject(response?.data?.data);
            setSubjectCount(response?.data?.data?.subjectCount);
        };
        apiCall();
    }, [pageNumber]);

    const handleStatus = (id) => {
        let apiCall = async () => {
            let response = await axios(
                `${BASE_URL}/api/freePdfSubject/active/${id}`,
                {
                    method: "PUT",
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                }
            );
            // console.log(response.data.data)
        };
        apiCall();
    };

    const handleUpdate = async (e) => {
        let response = await axios(
            `${BASE_URL}/api/freePdfSubject/update_sequence/${e}/?p=${pageNumber}`,
            // `${BASE_URL}/api/freePdfSubject/update_sequence/${e}/?p=${pageNumber}`, 
            {
                method: "PUT",
                headers: {
                    Authorization: localStorage.getItem("token"),
                },
                data: { "sequence": updateSequence },
            }
        );
        setSubject(response?.data?.data);
    };

    const handleDelete = (id) => {
        let apiCall = async () => {
            let res = await fetch(
                `${BASE_URL}/api/freePdfSubject/delete/${id}`,
                // `${BASE_URL}/api/freePdfSubject/delete/${id}`,
                {
                    method: "DELETE",
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                }
            );
            const result = await res?.json();
            let deletedUser = result?.data;
            setSubject(subject.filter((arr) => arr._id !== deletedUser._id))
            setSubjectCount((cur) => cur - 1)
        };
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                apiCall();
                Swal.fire({
                    title: "Deleted!",
                    text: "Subject has been deleted.",
                    icon: "success",
                });
            }
        });
    };

    const searchHandle = async (e) => {
        e.preventDefault();
        setShowIndex(true);
        setShowPagination(false);
        let response = await axios(
            `${BASE_URL}/api/freePdfSubject/search?title=${searchTitle}`,
            {
                method: "GET",
                headers: {
                    Authorization: localStorage.getItem("token"),
                },
            }
        );
        console.log(response?.data)
        if (!response?.data?.status) {
            alert(response?.data?.message);
            return
        } else {
            setSubject(response?.data?.data || []);
        }
    };

    return (
        <>
            {/* <Header /> */}
            {/* <Sidebar /> */}
            <section className="main-sec">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="dashboard-title">
                            <h4 className="dash-head">
                                <i className="fas fa-book me-2" />
                                Free Subject Pdf List
                            </h4>
                            <Link to="/free_subject_pdf_add" className="blu-btn">
                                <i className="far fa-plus me-2" />
                                Add Free Subject Pdf
                            </Link>
                        </div>
                        <div className="custom-bredcump">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/free_video_pdf_subject_list">
                                            Free Subject Pdf Management
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Free Subject Pdf List
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="cards bus-list">
                            <div className="bus-filter">
                                <div className="row justify-content-end">
                                    <div className="col-lg-12">
                                        <h4 className="table-head">
                                            Free Subject Pdf List({subjectCount || subject?.length})
                                        </h4>
                                    </div>
                                    <div className="col-lg-5">
                                        <form action="" onSubmit={searchHandle}>
                                            <div className="row justify-content-end gx-2 align-items-center">
                                                <div className="col-lg-6">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Search Subject..."
                                                        onChange={(e) => setSearchTitle(e.target.value)}
                                                    />
                                                </div>
                                                <div className="col-lg-2">
                                                    <button type="submit" className="blu-btn w-100">
                                                        Search
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="table table-responsive custom-table">
                                <table className="table table-borderless">
                                    <thead>
                                        <tr>
                                            <th>SR NO.</th>
                                            <th>Image</th>
                                            <th>Subject Name</th>
                                            <th>SEQUENCE</th>
                                            <th>status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {subject?.map((arr, i) => (
                                            <tr key={arr._id}>
                                                <th>{showIndex ? ++i : ++i + 10 * (pageNumber - 1)}</th>
                                                <td> <img
                                                    src={`${BASE_URL}/${arr.image}`}
                                                    className="img-40"
                                                    alt=""
                                                    onError={(e) => e.target.src = dummy}
                                                /></td>
                                                <td>
                                                    <Link to={`/free_pdf_list/${arr._id}`}>
                                                        {arr?.title} ({arr?.freePdfCount})
                                                    </Link>
                                                </td>
                                                <td>
                                                    <div className="d-flex table-box">
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            defaultValue={arr?.sequence}
                                                            onChange={(e) =>
                                                                setUpdateSequence(e.target.value)
                                                            }
                                                            min="0"
                                                        />
                                                        <button
                                                            className="btn btn-success ms-2"
                                                            onClick={() => handleUpdate(arr._id)}
                                                        >
                                                            Update
                                                        </button>
                                                    </div>
                                                </td>
                                                <td> <div className="form-check form-switch">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        defaultChecked={arr?.isActive}
                                                        onClick={() => handleStatus(arr._id)}
                                                    />
                                                </div>
                                                </td>
                                                <td>
                                                    <Link
                                                        to={`/free_subject_pdf_edit/${arr._id}`}
                                                        className="mx-2"
                                                    >
                                                        <img src={edit} alt="" />
                                                    </Link>
                                                    <Link
                                                        to="#"
                                                        onClick={() => handleDelete(arr?._id)}
                                                        className="mx-2"
                                                    >
                                                        <img src={trash} alt="" />
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {/* <div className="custom-pagination">
                  <ul className="pagination">
                    <li className="page-item">
                      <a className="page-link" href="#" aria-label="Previous">
                        <span aria-hidden="true">«</span>
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link active" href="#">
                        1
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        2
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        3
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#" aria-label="Next">
                        <span aria-hidden="true">»</span>
                      </a>
                    </li>
                  </ul>
                </div> */}
                                {showPagination && setSubject.length > 10 && <div className="custom-pagination">
                                    <ul className="pagination">
                                        <li className="page-item">
                                            <button
                                                className="page-link"
                                                to=""
                                                aria-label="Previous"
                                                disabled={pageNumber === 1 && true}
                                                onClick={() => setPageNumber((prev) => prev - 1)}
                                            >
                                                <span aria-hidden="true">«</span>
                                            </button>
                                        </li>
                                        {pageNumber >= 2 && (
                                            <li className="page-item">
                                                <Link className="page-link" to="">
                                                    {pageNumber - 1}
                                                </Link>
                                            </li>
                                        )}
                                        <li className="page-item">
                                            <Link className="page-link active" to="">
                                                {pageNumber}
                                            </Link>
                                        </li>
                                        <li className="page-item">
                                            <Link
                                                className="page-link"
                                                to=""
                                                onClick={() => setPageNumber((prev) => prev + 1)}
                                            >
                                                {pageNumber + 1}
                                            </Link>
                                        </li>
                                        <li className="page-item">
                                            <Link
                                                className="page-link"
                                                to=""
                                                onClick={() => setPageNumber((prev) => prev + 2)}
                                            >
                                                {pageNumber + 2}
                                            </Link>
                                        </li>
                                        <li className="page-item">
                                            <Link
                                                className="page-link"
                                                to=""
                                                aria-label="Next"
                                                onClick={() => setPageNumber((prev) => prev + 1)}
                                            >
                                                <span aria-hidden="true">»</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
