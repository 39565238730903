import React, { useState, useEffect } from "react";
// import Header from "../Layout/Header";
// import Sidebar from "../Layout/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import BASE_URL from "../config/baseUrl";
import axios from "axios";

export default function FreePdfAdd() {
    let navigate = useNavigate();
    let [img, setImg] = useState("");
    // let [pdfimg, setPdfImg] = useState("");
    let [showpdf, setShowPdf] = useState(false);

    let [formval, setFormval] = useState({
        title: "",
        image: "",
        // type: "",
        subject: "",
        pdf: "",
        videoLink: "",
    });

    let [subjectData, setSubjectData] = useState([]);
    // const [image, setImages] = useState([]);
    const [pdfSuject, setPdfSuject] = useState([]);

    const handelOnChange = (e) => {
        if (e.target.value === "pdf" || e.target.value === "video") {
            setShowPdf(true);
        }

        // if (e.target.name === "type" && e.target.value === "") {
        //     setShowPdf(false);
        // }
        if (e.target.name === "image") {
            const reader = new FileReader();
            reader.onload = () => {
                setImg(reader.result);
            };
            reader.readAsDataURL(e.target.files[0]);
            setFormval((currVal) => ({
                ...currVal,
                image: e.target.files[0],
            }));
        } else if (e.target.name === "pdf") {
            setFormval((currVal) => ({ ...currVal, ["pdf"]: e.target.files[0] }));
        } else {
            setFormval((currVal) => ({
                ...currVal,
                [e.target.name]: e.target.value,
            }));
        }
    };

    useEffect(() => {
        const apiCall = async () => {
            let response = await axios(
                `${BASE_URL}/api/freePdfSubject/alldata`,
                {
                    method: "GET",
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                }
            );

            setSubjectData(response.data.data);
        };
        apiCall();
        // const pdfSubjectApi = async () => {
        //   let response = await axios(
        //     `${BASE_URL}/api/freeVideoPdfSubject/alldata`,
        //     {
        //       method: "GET",
        //       headers: {
        //         Authorization: localStorage.getItem("token"),
        //       },
        //     }
        //   );
        //   console.log(response.data)
        //   setPdfSuject(response.data.data);
        // };
        // pdfSubjectApi();
    }, []);

    const handelOnSubmit = (e) => {
        e.preventDefault();
        if (!formval.title || !formval.subject || !formval.image) {
            alert("please fill all field");
            return;
        }
        if (formval.type === "video") {
            formval.pdf = "";
        } else {
            formval.videoLink = "";
        }
        // console.log(resultPdfApi)
        // if (!resultPdfApi) {
        //   return
        // }
        // console.log(formval)
        // return
        const postData = new FormData();
        postData.append("image", formval.image);
        postData.append("title", formval.title);
        postData.append("subject", formval.subject);
        // postData.append("type", formval.type);
        postData.append("pdf", formval.pdf);
        postData.append("videoLink", formval.videoLink);
        const apiCall = async () => {
            let response = await axios(`${BASE_URL}/api/freePdf/add`, {
                method: "POST",
                headers: {
                    Authorization: localStorage.getItem("token"),
                },
                data: postData,
            });
            // console.log(response.data)
            if (!response?.data?.status) {
                console.log(response.data);
                alert("some error accoured");
                return;
            }
            navigate("/free_pdf_list");
        };
        apiCall();
    };

    // let addPdfDataApi =()=>{
    //   console.log(pdfForm)
    //   if (
    //     !pdfForm.title ||
    //     !pdfForm.subject ||
    //     !pdfForm.pdf ||
    //     !pdfForm.image
    //   ) {
    //     alert("please fill Pdf  fields");
    //     return false;
    //   }
    //   // return
    //   const postData = new FormData();
    //   postData.append("image", pdfForm.image);
    //   postData.append("title", pdfForm.title);
    //   postData.append("subject", pdfForm.subject);
    //   postData.append("pdf", pdfForm.pdf);

    // (async () => {
    //  try {
    //    let response = await axios(`${BASE_URL}/api/freecoursepdf/add`, {
    //      method: "POST",
    //      headers: {
    //        Authorization: localStorage.getItem("token"),
    //      },
    //      data: postData,
    //    });
    //    console.log(response.data)
    //    if ((response?.data?.status)) {
    //     alert("some error accoured");
    //     return false;
    //   }
    //   return true
    //  } catch (error) {
    //   console.log(error)
    //   return false
    //  }
    //   // console.log(response.data.data)
    //   // console.log(response)
    //   // if (!(response?.data?.status)) {
    //   //   alert("some error accoured");
    //   //   return;
    //   // }
    // })();
    // }

    return (
        <>
            <section className="main-sec">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="dashboard-title">
                            <h4 className="dash-head">
                                <Link to="/free_pdf_list"><i className="fa-solid fa-circle-arrow-left pe-2"></i></Link>/{"  "}
                                <i className="fas fa-video me-2" />
                                Add Free Pdf
                            </h4>
                        </div>
                        <div className="custom-bredcump">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/free_pdf_add">Free Pdf</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Add Free Pdf
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="cards cstm-form">
                            <form id="imageUploadForm" action="" onSubmit={handelOnSubmit}>
                                <div className="row">
                                    {/* <div className="col-lg-4 mb-4">
                                        <label htmlFor="" className="form-label">
                                            Type
                                        </label>
                                        <select
                                            name="type"
                                            onChange={handelOnChange}
                                            className="form-select"
                                        >
                                            <option value="">Select Type</option>
                                            <option value={"pdf"}>Pdf</option>
                                            <option value={"video"}>Video</option>
                                        </select>
                                    </div> */}

                                    <div className="col-lg-4 mb-4">
                                        <label htmlFor="" className="form-label">
                                            Title
                                        </label>
                                        <input
                                            type="text"
                                            name="title"
                                            value={formval.title}
                                            className="form-control"
                                            onChange={handelOnChange}
                                            placeholder="Enter Title"
                                        />
                                    </div>
                                    <div className="col-lg-4 mb-4">
                                        <label className="form-label">subject</label>
                                        <select
                                            name="subject"
                                            onChange={handelOnChange}
                                            className="form-select"
                                        >
                                            <option>Select subject</option>
                                            {subjectData?.map((arr) => (
                                                <option key={arr?._id} value={arr._id}>
                                                    {arr?.title}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="col-lg-4 mb-4">
                                        <label htmlFor="" className="form-label">
                                            Image
                                        </label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            name="image"
                                            onChange={handelOnChange}
                                        />
                                        {img && (
                                            <img
                                                src={`${img}`}
                                                alt="Preview Image"
                                                className="img-50"
                                            />
                                        )}
                                    </div>

                                    {/* {showpdf && */}
                                    {/* (formval.type === "pdf" ? ( */}
                                    <div className="col-lg-3 mb-4">
                                        <label htmlFor="" className="form-label">
                                            Pdf
                                        </label>
                                        <input
                                            type="file"
                                            name="pdf"
                                            accept="application/pdf"
                                            onChange={handelOnChange}
                                            className="form-control"
                                        />
                                    </div>
                                    {/* ) : ( */}
                                    {/* <div className="col-lg-3 mb-4"> */}
                                    {/* <label htmlFor="" className="form-label">
                                                    Video Link
                                                </label>
                                                <input
                                                    type="url"
                                                    name="videoLink"
                                                    // accept="application/pdf"
                                                    placeholder="https://example.com"
                                                    onChange={handelOnChange}
                                                    className="form-control"
                                                // placeholder="Video Link"
                                                />
                                            </div>
                                        ))} */}

                                    <div className="col-lg-12 mb-4 text-center">
                                        <button className="thm-btn" type="submit">
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
