// import React, { useState, useEffect } from 'react';
// import RichTextEditor from 'react-rte';

// const RTEEditor = ({ value, onChange, editorTitle }) => {
//     const [editorValue, setEditorValue] = useState(
//         RichTextEditor.createValueFromString(value || '', 'html')
//     );

//     const handleChange = (value) => {
//         setEditorValue(value);
//         if (onChange) {
//             onChange(value.toString('html'));
//         }
//     };

//     useEffect(() => {
//         setEditorValue(RichTextEditor.createValueFromString(value || '', 'html'));
//     }, [value]);

//     return (
//         <>
//             <label className="form-label">{editorTitle}</label>
//             <RichTextEditor
//                 value={editorValue}
//                 onChange={handleChange}
//                 className="react-rte-container"
//             />
//         </>
//     );
// };

// export default RTEEditor;

// import React, { useState, useEffect } from 'react';
// import RichTextEditor from 'react-rte';

// const RTEEditor = ({ initialValue, onChange, editorTitle }) => {
//     const [editorState, setEditorState] = useState(() => {
//         return RichTextEditor.createEmptyValue();
//     });

//     useEffect(() => {
//         if (initialValue) {
//             setEditorState(RichTextEditor.createValueFromString(initialValue, 'html'));
//         }
//     }, [initialValue]);

//     const handleChange = (value) => {
//         setEditorState(value);
//         if (onChange) {
//             // Pass the HTML string to parent
//             onChange(value.toString('html'));
//         }
//     };
//     // const toolbarConfig = {
//     //     display: [
//     //         'INLINE_STYLE_BUTTONS',
//     //         'BLOCK_TYPE_BUTTONS',
//     //         'LINK_BUTTONS',
//     //         'BLOCK_TYPE_DROPDOWN',
//     //         // 'HISTORY_BUTTONS'
//     //     ],
//     //     INLINE_STYLE_BUTTONS: [
//     //         { label: 'Bold', style: 'BOLD' },
//     //         { label: 'Italic', style: 'ITALIC' },
//     //         { label: 'Underline', style: 'UNDERLINE' }
//     //     ],
//     //     BLOCK_TYPE_DROPDOWN: [
//     //         { label: 'Normal', style: 'unstyled' },
//     //         { label: 'Heading Large', style: 'header-one' },
//     //         { label: 'Heading Medium', style: 'header-two' },
//     //         { label: 'Heading Small', style: 'header-three' }
//     //     ],
//     //     BLOCK_TYPE_BUTTONS: [
//     //         { label: 'UL', style: 'unordered-list-item' },
//     //         { label: 'OL', style: 'ordered-list-item' }
//     //     ]
//     // };

//     return (
//         <>
//             <label className="form-label">{editorTitle}</label>
//             <RichTextEditor
//                 value={editorState}
//                 // toolbarConfig={toolbarConfig}
//                 onChange={handleChange}
//                 className="react-rte-container"
//             />
//         </>
//     );
// };

// export default RTEEditor;

// import React, { useState, useEffect } from 'react';
// import RichTextEditor from 'react-rte';

// const RTEEditor = ({ initialValue, onChange, editorTitle }) => {
//     const [editorState, setEditorState] = useState(
//         initialValue
//             ? RichTextEditor.createValueFromString(initialValue, 'html')
//             : RichTextEditor.createEmptyValue()
//     );

//     useEffect(() => {
//         if (initialValue !== null && initialValue !== undefined) {
//             setEditorState(RichTextEditor.createValueFromString(initialValue, 'html'));
//         }
//     }, [initialValue]);

//     const handleChange = (value) => {
//         setEditorState(value);

//         if (onChange) {
//             const contentHtml = value.toString('html');
//             onChange(contentHtml.trim() !== "" ? contentHtml : "<p><br/></p>");
//         }
//     };

//     return (
//         <>
//             <label className="form-label">{editorTitle}</label>
//             <RichTextEditor
//                 value={editorState}
//                 onChange={handleChange}
//                 className="react-rte-container"
//             />
//         </>
//     );
// };

// export default RTEEditor;


// import React, { useState, useEffect } from 'react';
// import RichTextEditor from 'react-rte';

// const RTEEditor = ({ initialValue, onChange, editorTitle }) => {
//     const [editorState, setEditorState] = useState(
//         initialValue
//             ? RichTextEditor.createValueFromString(initialValue, 'html')
//             : RichTextEditor.createEmptyValue()
//     );

//     useEffect(() => {
//         if (initialValue !== null && initialValue !== undefined) {
//             setEditorState(RichTextEditor.createValueFromString(initialValue, 'html'));
//         }
//     }, [initialValue]);

//     const handleChange = (value) => {
//         setEditorState(value);
//         console.log("Editor State:", value.toString('html'));
//         if (onChange) {
//             const contentHtml = value.toString('html');
//             console.log("Content HTML:", contentHtml);
//             onChange(contentHtml.trim() !== '' ? contentHtml : '<p><br/></p>');
//         }
//     };

//     return (
//         <>
//             <label className="form-label">{editorTitle}</label>
//             <RichTextEditor
//                 value={editorState}
//                 onChange={handleChange}
//                 className="react-rte-container"
//             />
//         </>
//     );
// };

// export default RTEEditor;


// import React, { useState, useEffect } from "react";
// import RichTextEditor from "react-rte";

// const RTEEditor = ({ markup, onChange }) => {
//     const [value, setValue] = useState(() =>
//         RichTextEditor.createValueFromString(markup, "html")
//     );

//     useEffect(() => {
//         setValue(RichTextEditor.createValueFromString(markup, "html"));
//     }, [markup]); // Update editor content when `markup` changes

//     const handleChange = (newValue) => {
//         setValue(newValue);
//         if (onChange) {
//             onChange(newValue.toString("html"));
//         }
//     };

//     return <RichTextEditor value={value} onChange={handleChange} />;
// };

// export default RTEEditor;

// import React, { useState, useEffect } from "react";
// import RichTextEditor from "react-rte";

// const RTEEditor = ({ value, onChange, editorTitle }) => {
//     const [editorValue, setEditorValue] = useState(() =>
//         RichTextEditor.createValueFromString(value || "", "html")
//     );

//     useEffect(() => {
//         const newValue = RichTextEditor.createValueFromString(value || "", "html");
//         if (value !== editorValue.toString("html")) {
//             setEditorValue(newValue);
//         }
//     }, [value]);

//     const handleChange = (newValue) => {
//         setEditorValue(newValue);
//         if (onChange) {
//             onChange(newValue.toString("html"));
//         }
//     };
//     const toolbarConfig = {
//         // display: [
//         //     'INLINE_STYLE_BUTTONS',
//         //     'BLOCK_TYPE_BUTTONS',
//         //     'LINK_BUTTONS',
//         //     'BLOCK_TYPE_DROPDOWN',
//         //     'HISTORY_BUTTONS',
//         //     'IMAGE_BUTTON'
//         // ],
//         IMAGE_BUTTON: {
//             urlEnabled: true,
//             uploadEnabled: true,
//             alignmentEnabled: true,
//             resizeEnabled: true, // Enable image resizing
//             defaultSize: {
//                 height: 'auto',
//                 width: 'auto',
//             },
//         }
//     };
//     return (
//         <div className="mb-3">
//             <label className="form-label">{editorTitle}</label>
//             <RichTextEditor
//                 value={editorValue}
//                 onChange={handleChange}
//                 preserveWhitespace={true}
//                 // toolbarConfig={toolbarConfig}
//                 className="rich-text-editor"
//             />
//         </div>
//     );
// };

// export default RTEEditor;

import React, { useState, useEffect } from "react";
import RichTextEditor from "react-rte";

const RTEEditor = ({ value, onChange, editorTitle }) => {
    const [editorValue, setEditorValue] = useState(() =>
        RichTextEditor.createValueFromString(value || "", "html")
    );

    useEffect(() => {
        const newValue = RichTextEditor.createValueFromString(value || "", "html");
        if (value !== editorValue.toString("html")) {
            setEditorValue(newValue);
        }
    }, [value]);

    const handleChange = (newValue) => {
        setEditorValue(newValue);
        if (onChange) {
            onChange(newValue.toString("html"));
        }
    };

    const toolbarConfig = {
        display: [
            'INLINE_STYLE_BUTTONS',
            'BLOCK_TYPE_BUTTONS',
            'LINK_BUTTONS',
            'BLOCK_TYPE_DROPDOWN',
            'HISTORY_BUTTONS',
            'IMAGE_BUTTON',
            'TEXT_COLOR',
            'BACKGROUND_COLOR'
        ],
        INLINE_STYLE_BUTTONS: [
            { label: 'Bold', style: 'BOLD' },
            { label: 'Italic', style: 'ITALIC' },
            { label: 'Underline', style: 'UNDERLINE' },
            { label: 'Strikethrough', style: 'STRIKETHROUGH' },
            { label: 'Code', style: 'CODE' },
            // { label: 'Red', style: 'COLOR_RED' }, // Updated style key
            // { label: 'Blue', style: 'COLOR_BLUE' },
            // { label: 'Yellow BG', style: 'BGCOLOR_YELLOW' },
            // { label: 'Pink BG', style: 'BGCOLOR_PINK' }
        ],
        BLOCK_TYPE_DROPDOWN: [
            { label: 'Normal', style: 'unstyled' },
            { label: 'H1', style: 'header-one' },
            { label: 'H2', style: 'header-two' },
            { label: 'H3', style: 'header-three' },
            { label: 'H4', style: 'header-four' },
            { label: 'H5', style: 'header-five' },
            { label: 'H6', style: 'header-six' },
            { label: 'Blockquote', style: 'blockquote' },
            { label: 'Code Block', style: 'code-block' }
        ],
        BLOCK_TYPE_BUTTONS: [
            { label: 'UL', style: 'unordered-list-item' },
            { label: 'OL', style: 'ordered-list-item' },
            { label: 'Blockquote', style: 'blockquote' }
        ],
        LINK_BUTTONS: {
            addLink: true,
            removeLink: true
        },
        HISTORY_BUTTONS: {
            undo: true,
            redo: true
        },
        IMAGE_BUTTON: {
            urlEnabled: true,
            uploadEnabled: true,
            alignmentEnabled: true,
            resizeEnabled: true,
            defaultSize: {
                height: 'auto',
                width: 'auto',
            }
        },
    };

    return (
        <div className="mb-3">
            <label className="form-label">{editorTitle}</label>
            <RichTextEditor
                value={editorValue}
                onChange={handleChange}
                preserveWhitespace={true}
                toolbarConfig={toolbarConfig}
                className="rich-text-editor"
            />
        </div>
    );
};

export default RTEEditor;




