import React,{useState} from 'react'
import axios from "axios"
import { Link ,useLocation} from 'react-router-dom';
import BASE_URL from '../config/baseUrl';
export default function QuestionImportCard() {
  let location = useLocation()
    const [file, setFile] = useState(null);
    const handleFileChange = (event) => {
      console.log(event.target.files[0])
        setFile(event.target.files[0]);
      };


    const handleSubmit = async (event) => {
    event.preventDefault();
    setFile("")
    if(!file){
      return alert("Please Enter Excel File First")
    }

    const formData = new FormData();
    formData.append('file', file);
    try {
      // const response = await axios.post(`http://localhost:1100/api/megatestquestion/upload`,formData,  {
      const response = await axios.post(location.pathname === "/megaquestionlist" ? `${BASE_URL}/api/megatestquestion/upload`:`${BASE_URL}/api/question/upload`,formData,  {
        headers: {
            Authorization: localStorage.getItem("token"),
            'Content-Type': 'multipart/form-data',
        }
      });
    //   alert(response.data);
      console.log(response.data)
      if(response.data.status){
        alert(response.data.message)
      }
      if(!(response.data.status)){
        alert(response.data.message)
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('Error uploading file');
      setFile("")
    }
  };

  return (
    <>
         <div className="cards">
              <form>
                <div className="row align-items-center">
                  <div className="col-lg-4">
                    <input type="file" className="form-control" onChange={handleFileChange} accept=".xls, .xlsx" />
                  </div>
                  <div className="col-lg-8">
                    <button className="thm-btn rounded me-1"  type="button" onClick={handleSubmit}  >Import</button>
                      
              {location.pathname === "/megaquestionlist" ? <Link className="blu-btn me-1" to={`${BASE_URL}/api/megatestquestion/excelfile-megatest-subject`}>Demo File</Link>
                : <Link className="blu-btn me-1" to={`${BASE_URL}/api/megatestquestion/excelfile-megatest-subject`}>Demo File</Link>}
                    {/* <Link className="blu-btn me-1" to={`${BASE_URL}/api/question/excelfile`}>Demo File</Link> */}
                    {/* <Link className="blu-btn me-1" to='http://localhost:8080/api/question/excelfile' >Export</Link> */}
                    {/* <button className="btn btn-primary rounded" type="button">Demo File</button>
                    vijay  https://qualitysocket.objectsquare.in:1100/api/megatestquestion/excelfile-megatest-subject
use this api to download excel demo excel sheet
                    */}
                  </div>
                </div>
               </form>
            </div>
    </>
  )
}
