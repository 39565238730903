import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import BASE_URL from "./Component/config/baseUrl";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./assets/css/dashboard.css";
import "./assets/css/dashboard-responsive.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { BrowserRouter, Routes, Route, useNavigate, Navigate } from "react-router-dom";

import Protected from "./Component/Protected";
import Dashboard from "./Component/Home/Dashboard";
import Login from "./Component/Login/Login";
import User_list from "./Component/Users/User_list";
import User_add from "./Component/Users/User_add";
import User_edit from "./Component/Users/User_edit";
import User_details from "./Component/Users/User_details";

import VideoCourse_list from "./Component/Video_Courses/VideoCourse_list";
import VideoCourse_add from "./Component/Video_Courses/VideoCourse_add";
import VideoCourse_edit from "./Component/Video_Courses/VideoCourse_edit";
import VideoCourse_details from "./Component/Video_Courses/VideoCourse_details";

import CourseSubjectList from "./Component/Video_Courses/CourseSubjectList";
import CourseTopicList from "./Component/Video_Courses/CourseTopicList";



import VideoCourseCategory_add from "./Component/Video_Couse_Category/VideoCourseCategory_add";
import VideoCourseCategory_list from "./Component/Video_Couse_Category/VideoCourseCategory_list";
import VideoCourseCategory_edit from "./Component/Video_Couse_Category/VideoCourseCategory_edit";

import LiveVideoCourse_add from "./Component/Live_Video_Course/LiveVideoCourse_add";
import LiveVideoCourse_list from "./Component/Live_Video_Course/LiveVideoCourse_list";
import LiveVideoCourse_edit from "./Component/Live_Video_Course/LiveVideoCourse_edit";
import LiveVideoCourse_details from "./Component/Live_Video_Course/LiveVideoCourse_details";

// import VideoCourseCategory_list from "./Component/Live_Video_Couse_Category/LiveVideoCourseCategory_list";
import LiveVideoCourseCategory_list from "./Component/Live_Video_Couse_Category/LiveVideoCourseCategory_list";
import LiveVideoCourseCategory_add from "./Component/Live_Video_Couse_Category/LiveVideoCourseCategory_add";
import LiveVideoCourseCategory_edit from "./Component/Live_Video_Couse_Category/LiveVideoCourseCategory_edit";

import Role_list from "./Component/Role/Role_list";
import NewRole from "./Component/Role/NewRole";
import Role_edit from "./Component/Role/Role_edit";
import Role_view from "./Component/Role/Role_view";

import Permission_list from "./Component/Permission/PermissionList";

import Test_list from "./Component/Test/Test_list";
import Test_add from "./Component/Test/Test_add";
import Test_edit from "./Component/Test/Test_edit";

import Test_Category_list from "./Component/Test_Category/Test_Category_list";
import Test_Category_add from "./Component/Test_Category/Test_Category_add";
import Test_Category_edit from "./Component/Test_Category/Test_Category_edit";

import TestPackage_list from "./Component/Test_Package/TestPackage_list";
import TestPackage_add from "./Component/Test_Package/TestPackage_add";
import TestPackage_edit from "./Component/Test_Package/TestPackage_Edit";

import Question_List from "./Component/Question_Bank/Question_List";
import Question_Add from "./Component/Question_Bank/Question_Add";
import Question_edit from "./Component/Question_Bank/Question_edit";
import Question_view from "./Component/Question_Bank/Question_view";
import Test_Assign_Question_add from "./Component/TestAssignQuestion/Test_Assign_Question_add";

import Subject_list from "./Component/Subject/Subject_list";
import Subject_add from "./Component/Subject/Subject_add";
import Subject_edit from "./Component/Subject/Subject_edit";

import Ras_Package_list from "./Component/RasPackage/Ras_Package_list";
import Ras_Package_Add from "./Component/RasPackage/Ras_Package_add";
import Ras_Package_Edit from "./Component/RasPackage/Ras_Package_Edit";
import Ras_Package_Assign_Test_List from "./Component/RasPackage/Ras_Package_Assign_Test_List";

import Ras_Package_Test_list from "./Component/Ras_Package_Test_List/Ras_Package_Test_List";
import Ras_Package_Test_Add from "./Component/Ras_Package_Test_List/Ras_Package_Test_Add";
import Ras_Package_Test_Edit from "./Component/Ras_Package_Test_List/Ras_Package_Test_Edit";
import Layout from "./Layout";

import FreeVideoPdf_List from "./Component/FreeVideoPdf/FreeVideoPdf_List";
import FreeVideoPdf_Add from "./Component/FreeVideoPdf/FreeVideoPdf_Add";
import FreeVideoPdf_Edit from "./Component/FreeVideoPdf/FreeVideoPdf_Edit";

// /add test list in assign test
// free pdf subject 
import FreePdfSubject_add from "./Component/FreeVideoPdf/FreePdfSubject/FreePdfSubject_add";
import FreePdfSubject_edit from "./Component/FreeVideoPdf/FreePdfSubject/FreePdfSubject_edit";
import FreePdfSubject_list from "./Component/FreeVideoPdf/FreePdfSubject/FreePdfSubject_list";



import FreeVideoPdfSubject_add from "./Component/FreeVideoPdfSubject/FreeVideoPdfSubject_add";
import FreeVideoPdfSubject_edit from "./Component/FreeVideoPdfSubject/FreeVideoPdfSubject_edit";
import FreeVideoPdfSubject_list from "./Component/FreeVideoPdfSubject/FreeVideoPdfSubject_list";




import Banner_list from "./Component/Banner/Banner_list";
import Banner_add from "./Component/Banner/Banner_add";
import Banner_edit from "./Component/Banner/Banner_edit";
import Unauthorized from "./Component/config/Unauthorized";
import RouteNotFind from "./Component/config/RouteNotFind";

import RolePermissionContext from "./contextApi/rolePermissionContext";
// *******************************************************************
import BannershowList from "./Component/Banner/BannershowList";
import BannerAdd from "./Component/Banner/BannerAdd";
import BannerEdit from "./Component/Banner/BannerEdit";
import LiveVideoCourseList from "./Component/Live_Video_Course/LiveVideoCourseList";
import SubjectShowList from "./Component/Live_Video_Course/SubjectShowList";
import SubjectAdd from "./Component/Live_Video_Course/SubjectAdd";
import TopicList from "./Component/Live_Video_Course/TopicList";
import TopicAdd from "./Component/Live_Video_Course/TopicAdd";
import CommentList from "./Component/Live_Video_Course/CommentList";
import CommentAdd from "./Component/Live_Video_Course/CommentAdd";
///////////videocourse****
import VideoCourseCategoryList from "./Component/Video_Couse_Category/VideoCourseCategoryList";
import VideoCourseList from "./Component/Video_Couse_Category/VideoCourseList";
import Subject from "./Component/Video_Couse_Category/Subject";
import TopicListData from "./Component/Video_Couse_Category/TopicListData";
//////testcate****
import TestCategoryList from "./Component/Test_Category/TestCategoryList";
import TestPackageList from "./Component/Test_Category/TestPackageList";
import TestList from "./Component/Test_Category/TestList";
import MegaTestList from "./Component/Mega_test/MegaTestList";
import MegaTestAdd from "./Component/Mega_test/MegaTestAdd";
import DeepLinkList from "./Component/DeepLink/DeepLinkList";
import MegaTestEdit from "./Component/Mega_test/MegaTestEdit";
import MegaQuestionList from "./Component/MegaQuestions/MegaQuestionList";
import ComboOfferList from "./Component/Combo/ComboOfferList";
import ComboOfferAdd from "./Component/Combo/ComboOfferAdd";
import ComboOfferEdit from "./Component/Combo/ComboOfferEdit";
import MegaQuestionAdd from "./Component/MegaQuestions/MegaQuestionAdd";
import MegaQuestionView from "./Component/MegaQuestions/MegaQuestionView";
import MegaQuestionEdit from "./Component/MegaQuestions/MegaQuestionEdit";
import MegaTestSubject_list from "./Component/MegaTestSubject/MegaTestSubject_list";
import MegaTestSubject_add from "./Component/MegaTestSubject/MegaTestSubject_add";
import MegaTestSubject_edit from "./Component/MegaTestSubject/MegaTestSubject_edit";



import TestAssignQuestionAdd from "./Component/Mega_test/TestAssignQuestionAdd";

import AttendTestList from "./Component/attend_test/AttendTestList";
import RoleList from "./Component/AdminRole/RoleList";
import RoleView from "./Component/AdminRole/RoleView";
import RoleAdd from "./Component/AdminRole/RoleAdd";
import RoleEdit from "./Component/AdminRole/RoleEdit";
import PermissionCatList from "./Component/AdminPermission/PermissionCatList";
import PermissionAdd from "./Component/AdminPermission/PermissionAdd";
import ComboOfferAddPackage from "./Component/Combo/ComboOfferAddPackage";
import PermissionEdit from "./Component/AdminPermission/PermissionEdit";
import SubPermissionCatList from "./Component/AdminSubPermission/SubPermissionCatList";
import SubPermissionAdd from "./Component/AdminSubPermission/SubPermissionAdd";
import SubPermissionEdit from "./Component/AdminSubPermission/SubPermissionEdit";
import UserRoleList from "./Component/UserRole/UserRoleList";
import UserRoleAdd from "./Component/UserRole/UserRoleAdd";
import UserRoleEdit from "./Component/UserRole/UserRoleEdit";
import RolePermissionList from "./Component/RolePermission/RolePermissionList";
import RolePermissionAdd from "./Component/RolePermission/RolePermissionAdd";
import RolePermissionEdit from "./Component/RolePermission/RolePermissionEdit";
import AdminUserList from "./Component/AdminUser/AdminUserList";
import AdminUserAdd from "./Component/AdminUser/AdminUserAdd";
import AdminUserEdit from "./Component/AdminUser/AdminUserEdit";
import AddUserRole from "./Component/AdminUser/AddUserRole";
import CommentSocket from "./Component/Live_Video_Course/CommentSocket"

import CmsList from "./Component/Cms/CmsList";
import CmsAdd from "./Component/Cms/CmsAdd";
import CmsEdit from "./Component/Cms/CmsEdit";
import FreePdfList from "./Component/FreePdf/FreePdfList";
import FreePdfAdd from "./Component/FreePdf/FreePdfAdd";
import FreePdfEdit from "./Component/FreePdf/FreePdfEdit";
import FreeSubjectPdfList from "./Component/FreeSubjectPdf/FreeSubjectPdfList";
import FreeSubjectPdfEdit from "./Component/FreeSubjectPdf/FreeSubjectPdfEdit";
import FreeSubjectPdfAdd from "./Component/FreeSubjectPdf/FreeSubjectPdfAdd";


function App() {

  const user = JSON.parse(localStorage?.getItem("user"));
  let token = localStorage.getItem("token");
  // let token = localStorage.getItem("token");
  // console.log("token",token)

  // const { userPermission, setUserPermission } = useContext(
  //   RolePermissionContext
  // );   

  // let fetchData = async (id) => {
  //   // console.log(user?.user_type?._id,"this is id");
  //   if (!id) {
  //     return;
  //   }
  //   let response = await axios(
  //     `${BASE_URL}/api/rolepermission/show/${user?.user_type?._id || id}`,
  //     {
  //       method: "GET",
  //       headers: {
  //         Authorization: localStorage.getItem("token"),
  //       },
  //     }
  //   );
  //   console.log(response?.data);

  //   let filter = response?.data?.data?.permissionCategoryId?.map(
  //     (arr) => arr.category
  //   );
  //   setUserPermission(filter);
  // };

  // let apiCall = async () => {
  //   // let response =
  //   //  await axios(`${BASE_URL}/api/user/user_data/${user?._id}`,
  //   //    {
  //   //   method: "GET",
  //   //   headers: {
  //   //     Authorization: localStorage.getItem("token"),
  //   //   },
  //   // });
  //   // console.log(response?.data);
  //   // console.log(response?.data.data?.user_type,"this is id  ");
  //   // fetchData(response?.data?.data?.user_type);
  // };
  // useEffect(() => {
  //   // apiCall();
  // }, []);
  // const [permission,setPermission] = useState([]);
  // useEffect(() => {
  //   fetchpermission();
  // }, []);
  // const fetchpermission = async() => {
  //   try {
  //     const response = await axios(`${BASE_URL}/api/user/verify-routes`, {
  //       method: "GET",
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("token")}`,
  //       },
  //     });
  //     console.log("responsepermission",response.data.data)
  //     setPermission(response.data.data)
  //   } catch (error) {
  //     console.log("fetch permission error", error);
  //   }
  // };
  // const [userPermissions, setUserPermissions] = useState([]);

  // console.log("Permission",permission)

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Layout />}>
            <Route
              index
              element={
                <Protected Component={Dashboard} allowedRoles={"home"} />
              }
            />
            {/* {userPermission?.includes("user Mangement") && ( */}
            <>
              <Route
                path="/user_list"
                element={
                  <Protected Component={User_list} allowedRoles={"user_list"}
                  />
                }
              />
              <Route
                path="/user_add"
                element={
                  <Protected Component={User_add} allowedRoles={"user_add"} />
                }
              />
              <Route
                path="/User_edit/:id"
                element={
                  <Protected Component={User_edit} allowedRoles={"User-edit"} />
                }
              />
              <Route
                path="/user_details/:id"
                element={
                  <Protected
                    Component={User_details}
                    allowedRoles={"user_details"}
                  />
                }
              />
              <Route
                path="/adminuserlist"
                element={
                  <Protected
                    Component={AdminUserList}
                    allowedRoles={"adminuserlist"}
                  />
                }
              />
              <Route
                path="/adminuserregister"
                element={
                  <Protected
                    Component={AdminUserAdd}
                    allowedRoles={"adminuserregister"}
                  />
                }
              />
              <Route
                path="/adminuseredit/:id"
                element={
                  <Protected
                    Component={AdminUserEdit}
                    allowedRoles={"adminuseredit"}
                  />
                }
              />
              {/* ************ */}
              <Route
                path="/adduserrole/:id"
                element={
                  <Protected
                    Component={AddUserRole}
                    allowedRoles={"adduserrole"}
                  />
                }
              />
            </>
            {/* // )} */}

            {/* {userPermission?.includes("video Mangement") && ( */}
            <>
              <Route
                path="/video_course_list"
                element={
                  <Protected
                    Component={VideoCourse_list}
                    allowedRoles={"video_course_list"}
                  />
                }
              />
              {/* ***************** baki*/}
              <Route
                path="/videocourselist/:id"
                element={
                  <Protected
                    Component={VideoCourseList}
                    allowedRoles={"read video"}
                  />
                }
              />
              {/* *********************** */}
              <Route
                path="/video_course_add"
                element={
                  <Protected
                    Component={VideoCourse_add}
                    allowedRoles={"video_course_add"}
                  />
                }
              />
              <Route
                path="/video_course_edit/:id"
                element={
                  <Protected
                    Component={VideoCourse_edit}
                    allowedRoles={"video_course_edit"}
                  />
                }
              />
              <Route
                path="/video_course_details/:id"
                element={
                  <Protected
                    Component={VideoCourse_details}
                    allowedRoles={"video_course_details"}
                  />
                }
              />

              {/* <Route
                  path="/video_course_category_list"
                  element={<Protected Component={VideoCourseCategory_list} />}
                /> */}
              {/* ******************** */}
              <Route
                path="/videocoursecategorylist"
                element={<Protected Component={VideoCourseCategoryList}
                  allowedRoles={"videocoursecategorylist"}
                />}
              />
              {/* **************** */}
              <Route
                path="/video_course_category_add"
                element={<Protected Component={VideoCourseCategory_add}
                  allowedRoles={"video_course_category_add"}
                />}
              />
              <Route
                path="/VideoCourseCategory_edit/:id"
                element={<Protected Component={VideoCourseCategory_edit}
                  allowedRoles={"VideoCourseCategory_edit"}
                />}
              />

              {/* ********subject route****** */}
              <Route
                path="/subject/:subjectid"
                element={<Protected Component={Subject}
                  allowedRoles={"subject"}
                />}
              />
              {/* ********Topic route****** */}
              <Route
                path="/topiclistdata/:id/:topicid"
                element={<Protected Component={TopicListData}
                  allowedRoles={"topiclistdata"}
                />}
              />
            </>
            {/* )} */}

            {/* {userPermission?.includes("live video Mangement") && ( */}
            <>
              <Route
                path="/livevideocourselist"
                // element={<Protected Component={LiveVideoCourseList}
                element={<Protected Component={LiveVideoCourse_list}
                  allowedRoles={"livevideocourselist"}
                />}
              />
              <Route
                path="/subjectshowlist/:id"
                element={<Protected Component={SubjectShowList}
                  allowedRoles={"subjectshowlist"}
                />}
              />
              <Route
                path="/subjectadd"
                element={<Protected Component={SubjectAdd}
                  allowedRoles={"subjectadd"}
                />}
              />
              <Route
                path="/topiclist/:subjectId/:courseId"
                element={<Protected Component={TopicList}
                  allowedRoles={"topiclist"}
                />}
              />
              <Route
                path="/topicadd"
                element={<Protected Component={TopicAdd}
                  allowedRoles={"topicadd"}
                />}
              />
              <Route
                path="/commentlist/:tid"
                element={<Protected Component={CommentList}
                  allowedRoles={"commentlist"}
                />}
              />
              <Route
                path="/commentadd"
                element={<Protected Component={CommentAdd}
                  allowedRoles={"commentadd"}
                />}
              />

              {/* <Route
                path="/live_video_course_list"
                element={<Protected Component={LiveVideoCourse_list}
                allowedRoles={"topicadd"}
                />}
              /> */}
              <Route
                path="/live_video_course_add"
                element={<Protected Component={LiveVideoCourse_add}
                  allowedRoles={"live_video_course_add"}
                />}
              />
              <Route
                path="/live_video_course_edit/:id"
                element={<Protected Component={LiveVideoCourse_edit}
                  allowedRoles={"live_video_course_edit"}
                />}
              />
              <Route
                path="/live_video_course_details/:id"
                element={<Protected Component={LiveVideoCourse_details}
                  allowedRoles={"live_video_course_details"}
                />}
              />
              <Route
                path="/subject-list/:id"
                element={<Protected Component={CourseSubjectList}
                  allowedRoles={"live_video_course_details"}
                />}
              />
              <Route
                path="/course-topic-list/:pacakge_id/:subject_id"
                element={<Protected Component={CourseTopicList}
                  allowedRoles={"live_video_course_details"}
                />}
              />

              <Route
                path="/live_video_course_category_list"
                element={<Protected Component={LiveVideoCourseCategory_list}
                  allowedRoles={"live_video_course_category_list"}
                />}
              />
              <Route
                path="/live_video_course_category_add"
                element={<Protected Component={LiveVideoCourseCategory_add}
                  allowedRoles={"live_video_course_category_add"}
                />}
              />
              <Route
                path="/live_video_course_category_edit/:id"
                element={<Protected Component={LiveVideoCourseCategory_edit}
                  allowedRoles={"live_video_course_category_edit"}
                />}
              />
            </>
            {/* )} */}

            {/* {userPermission?.includes("role Mangement") && ( */}
            <>
              <Route
                path="/role_list"
                element={
                  <Protected Component={Role_list} allowedRoles={"read role"} />
                }
              />
              <Route
                path="/role_add"
                element={
                  <Protected Component={NewRole} allowedRoles={"add role"} />
                }
              />
              <Route
                path="/role_edit/:id"
                element={
                  <Protected
                    Component={Role_edit}
                    allowedRoles={"delete role"}
                  // onSuccess={fetchData}
                  />
                }
              />
              <Route
                path="/role_view/:id"
                element={
                  <Protected Component={Role_view} allowedRoles={"edit role"} />
                }
              />

              <Route
                path="/permission_list"
                element={
                  <Protected
                    Component={Permission_list}
                    allowedRoles={"allowed"}
                  />
                }
              />
              {/* ***************** */}
              <Route
                path="/rolelist"
                element={
                  <Protected Component={RoleList} allowedRoles={"rolelist"} />
                }
              />
              <Route
                path="/roleview/:id"
                element={
                  <Protected Component={RoleView} allowedRoles={"roleview"} />
                }
              />
              <Route
                path="/roleadd"
                element={
                  <Protected Component={RoleAdd} allowedRoles={"roleadd"} />
                }
              />
              <Route
                path="/roleedit/:id"
                element={
                  <Protected
                    Component={RoleEdit}
                    allowedRoles={"roleedit"}
                  />
                }
              />
              <Route
                path="/permissioncatlist"
                element={
                  <Protected
                    Component={PermissionCatList}
                    allowedRoles={"permissioncatlist"}
                  />
                }
              />
              <Route
                path="/permissioncatadd"
                element={
                  <Protected
                    Component={PermissionAdd}
                    allowedRoles={"permissioncatadd"}
                  />
                }
              />
              <Route
                path="/permissioncatedit/:id"
                element={
                  <Protected
                    Component={PermissionEdit}
                    allowedRoles={"permissioncatedit"}
                  />
                }
              />
              <Route
                path="/subpermissioncatlist"
                element={
                  <Protected
                    Component={SubPermissionCatList}
                    allowedRoles={"subpermissioncatlist"}
                  />
                }
              />
              <Route
                path="/subpermissioncatadd"
                element={
                  <Protected
                    Component={SubPermissionAdd}
                    allowedRoles={"subpermissioncatadd"}
                  />
                }
              />
              <Route
                path="/subpermissioncatedit/:id"
                element={
                  <Protected
                    Component={SubPermissionEdit}
                    allowedRoles={"subpermissioncatedit"}
                  />
                }
              />
              <Route
                path="/userrolelist"
                element={
                  <Protected
                    Component={UserRoleList}
                    allowedRoles={"userrolelist"}
                  />
                }
              />
              {/* <Route
                path="/userroleadd"
                element={
                  <Protected
                    Component={UserRoleAdd}
                    allowedRoles={"allowed"}
                  />
                }
              /> */}
              <Route
                path="/userroleedit/:id"
                element={
                  <Protected
                    Component={UserRoleEdit}
                    allowedRoles={"userroleedit"}
                  />
                }
              />
              <Route
                path="/rolepermissionlist"
                element={
                  <Protected
                    Component={RolePermissionList}
                    allowedRoles={"rolepermissionlist"}
                  />
                }
              />
              <Route
                path="/rolepermissionadd"
                element={
                  <Protected
                    Component={RolePermissionAdd}
                    allowedRoles={"rolepermissionadd"}
                  />
                }
              />
              <Route
                path="/rolepermissionedit/:id"
                element={
                  <Protected
                    Component={RolePermissionEdit}
                    allowedRoles={"rolepermissionedit"}
                  />
                }
              />
            </>
            {/* )} */}

            {/* {userPermission?.includes("test Mangement") && ( */}
            <>
              <Route
                path="/test_list"
                element={
                  <Protected Component={Test_list} allowedRoles={"test_list"} />
                }
              />
              <Route
                path="/test_add"
                element={
                  <Protected Component={Test_add} allowedRoles={"test_add"} />
                }
              />
              <Route
                path="/test-edit/:id"
                element={
                  <Protected Component={Test_edit} allowedRoles={"test-edit"} />
                }
              />

              {/* <Route
                  path="/test_category_list"
                  element={
                    <Protected
                      Component={Test_Category_list}
                      allowedRoles={"read test category"}
                    />
                  }
                /> */}
              {/* ********** *** *********** */}
              <Route
                path="/testcategorylist"
                element={
                  <Protected
                    Component={TestCategoryList}
                    allowedRoles={"testcategorylist"}
                  />
                }
              />
              <Route
                path="/test_category_add"
                element={
                  <Protected
                    Component={Test_Category_add}
                    allowedRoles={"test_category_add"}
                  />
                }
              />
              <Route
                path="/test_category_edit/:id"
                element={
                  <Protected
                    Component={Test_Category_edit}
                    allowedRoles={"test_category_edit"}
                  />
                }
              />

              <Route
                path="/test_package_list"
                element={<Protected Component={TestPackage_list}
                  allowedRoles={"test_package_list"}
                />}
              />
              <Route
                path="/test_package_add"
                element={<Protected Component={TestPackage_add}
                  allowedRoles={"test_package_add"}
                />}
              />
              <Route
                path="/testPackage_edit/:id"
                element={<Protected Component={TestPackage_edit}
                  allowedRoles={"testPackage_edit"}
                />}
              />

              <Route
                path="/question_list"
                element={<Protected Component={Question_List}
                  allowedRoles={"question_list"}
                />}
              />
              <Route
                path="/question_add"
                element={<Protected Component={Question_Add}
                  allowedRoles={"question_add"}
                />}
              />
              <Route
                path="/question_edit/:id"
                element={<Protected Component={Question_edit}
                  allowedRoles={"question_edit"}
                />}
              />
              <Route
                path="/question_view/:id"
                element={<Protected Component={Question_view}
                  allowedRoles={"question_view"}
                />}
              />

              <Route
                path="/subject_list"
                element={<Protected Component={Subject_list} />}
              />
              <Route
                path="/subject_add"
                element={<Protected Component={Subject_add} />}
              />
              <Route
                path="/subject_edit/:id"
                element={<Protected Component={Subject_edit} />}
              />

              <Route
                path="/test_assign_duestion_add/:testid/:subjectid/:totalQuestion"
                element={<Protected Component={Test_Assign_Question_add}
                  allowedRoles={"test_assign_duestion_add"}
                />}
              />

              {/* ********vvvv******** */}
              <Route
                path="/testpackagelist/:catid"
                element={<Protected Component={TestPackageList}
                  allowedRoles={"testpackagelist"}
                />}
              />
              <Route
                path="/testlist/:packid"
                element={<Protected Component={TestList}
                  allowedRoles={"testlist"}
                />}
              />

              {/* ****************Mega Test********* */}
              <Route
                path="/megatestlist"
                element={<Protected Component={MegaTestList}
                  allowedRoles={"megatestlist"}
                />}
              />
              <Route
                path="/megatestadd"
                element={<Protected Component={MegaTestAdd}
                  allowedRoles={"megatestadd"}
                />}
              />
              <Route
                path="/megatestedit/:id"
                element={<Protected Component={MegaTestEdit}
                  allowedRoles={"megatestedit"}
                />}
              />

              <Route
                path="/testassignquestionadd/:testid/:subjectid/:totalQuestion"
                element={<Protected Component={TestAssignQuestionAdd}
                  allowedRoles={"testassignquestionadd"}
                />}
              />
              <Route
                path="/megaquestionlist"
                element={<Protected Component={MegaQuestionList}
                  allowedRoles={"megaquestionlist"}
                />}
              />
              <Route
                path="/megaquestionadd"
                element={<Protected Component={MegaQuestionAdd}
                  allowedRoles={"megaquestionadd"}
                />}
              />
              <Route
                path="/megaquestionview/:id"
                element={<Protected Component={MegaQuestionView}
                  allowedRoles={"megaquestionview"}
                />}
              />
              <Route
                path="/megaquestionedit/:id"
                element={<Protected Component={MegaQuestionEdit}
                  allowedRoles={"megaquestionedit"}
                />}
              />
              {/* megatestsubject */}

              <Route
                path="/megatestsubject_list"
                element={<Protected Component={MegaTestSubject_list}
                  allowedRoles={"megatestsubject_list"}
                />} />
              <Route
                path="/megatestsubject_add"
                element={<Protected Component={MegaTestSubject_add}
                  allowedRoles={"megatestsubject_add"}
                />} />
              <Route
                path="/megatestsubject_edit/:id"
                element={<Protected Component={MegaTestSubject_edit}
                  allowedRoles={"megatestsubject_edit"}
                />} />
            </>
            {/* )} */}
            {/* Ras_Package_list  */}

            {/* {userPermission?.includes("ras package Mangement") && ( */}
            <>
              <Route
                path="/ras_package_list"
                element={<Protected Component={Ras_Package_list}
                  allowedRoles={"ras_package_list"}
                />}
              />
              <Route
                path="/ras_package_add"
                element={<Protected Component={Ras_Package_Add}
                  allowedRoles={"ras_package_add"}
                />}
              />
              <Route
                path="/ras_package_edit/:id"
                element={<Protected Component={Ras_Package_Edit}
                  allowedRoles={"ras_package_edit"}
                />}
              />
              <Route
                path="/ras_package_assign_test_list/:id"
                element={<Protected Component={Ras_Package_Assign_Test_List}
                  allowedRoles={"ras_package_assign_test_list"}
                />}
              />

              <Route
                path="/ras_package_test_list"
                element={<Protected Component={Ras_Package_Test_list}
                  allowedRoles={"ras_package_test_list"}
                />}
              />
              <Route
                path="/ras_package_test_add"
                element={<Protected Component={Ras_Package_Test_Add}
                  allowedRoles={"ras_package_test_add"}
                />}
              />
              <Route
                path="/ras_package_test_edit/:id"
                element={<Protected Component={Ras_Package_Test_Edit}
                  allowedRoles={"ras_package_test_edit"}
                />}
              />
            </>
            {/* // )} */}

            {/* {userPermission?.includes("free video/Pdf Mangement") && ( */}
            <>
              <Route
                path="/free_video_pdf_list"
                element={<Protected Component={FreeVideoPdf_List}
                  allowedRoles={"free_video_pdf_list"}
                />}
              />
              <Route
                path="/free_video_pdf_list/:id"
                element={<Protected Component={FreeVideoPdf_List}
                  allowedRoles={"free_video_pdf_list"}
                />}
              />
              <Route
                path="/free_video_pdf_Add"
                element={<Protected Component={FreeVideoPdf_Add}
                  allowedRoles={"free_video_pdf_Add"}
                />}
              />
              <Route
                path="/free_video_pdf_edit/:id"
                element={<Protected Component={FreeVideoPdf_Edit}
                  allowedRoles={"free_video_pdf_edit"}
                />}
              />

              <Route
                path="/free_video_pdf_subject_add"
                element={<Protected Component={FreeVideoPdfSubject_add}
                  allowedRoles={"free_video_pdf_subject_add"}

                />}
              />
              <Route
                path="/free_video_pdf_subject_edit/:id"
                element={<Protected Component={FreeVideoPdfSubject_edit}
                  allowedRoles={"free_video_pdf_subject_edit"}
                />}
              />
              <Route
                path="/free_video_pdf_subject_list"
                element={<Protected Component={FreeVideoPdfSubject_list}
                  allowedRoles={"free_video_pdf_subject_list"}

                />}
              />
            </>
            {/* )} */}


            {/* free pdf subject  */}
            <Route
              path="/free_pdf_subject_add"
              element={<Protected Component={FreePdfSubject_add}
              // allowedRoles={"free_pdf_subject_list"}
              />}
            />
            <Route
              path="/free_pdf_subject_edit"
              element={<Protected Component={FreePdfSubject_edit}
              // allowedRoles={"free_pdf_subject_list"}
              />}
            />
            <Route
              path="/free_pdf_subject_list"
              element={<Protected Component={FreePdfSubject_list}
              // allowedRoles={"free_pdf_subject_list"}
              />}
            />

            {/*new free pdf subject  */}
            <Route
              path="/free_pdf_add"
              element={<Protected Component={FreePdfAdd}
              // allowedRoles={"free_pdf_subject_list"}
              />}
            />
            <Route
              path="/free_pdf_edit/:id"
              element={<Protected Component={FreePdfEdit}
              // allowedRoles={"free_pdf_subject_list"}
              />}
            />
            {/* <Route
              path="/free_pdf_list"
              element={<Protected Component={FreePdfList}
              // allowedRoles={"free_pdf_subject_list"}
              />}
            /> */}
            <Route path="/free_pdf_list" element={<FreePdfList />} />
            <Route path="/free_pdf_list/:id" element={<FreePdfList />} />
            <Route
              path="/free_subject_pdf_add"
              element={<Protected Component={FreeSubjectPdfAdd}
              // allowedRoles={"free_pdf_subject_list"}
              />}
            />
            <Route
              path="/free_subject_pdf_edit/:id"
              element={<Protected Component={FreeSubjectPdfEdit}
              // allowedRoles={"free_pdf_subject_list"}
              />}
            />
            <Route
              path="/free_subject_pdf_list"
              element={<Protected Component={FreeSubjectPdfList}
              // allowedRoles={"free_pdf_subject_list"}
              />}
            />


            {/* {userPermission?.includes("banner Mangement") && ( */}
            <>
              <Route
                path="/banner_list"
                element={<Protected Component={Banner_list}
                  allowedRoles={"banner_list"}
                />}
              />
              <Route
                path="/banner_add"
                element={<Protected Component={Banner_add}
                  allowedRoles={"banner_list"}
                />}
              />
              <Route
                path="/banner_edit/:id"
                element={<Protected Component={Banner_edit}
                  allowedRoles={"banner_list"}
                />}
              />
              <Route
                path="/Unauthorized_user"
                element={<Protected Component={Unauthorized} />}
              />
              {/* ****************Banner******** */}
              <Route
                path="/bannershowlist"
                element={<Protected Component={BannershowList}
                  allowedRoles={"bannershowlist"}
                />}
              />
              <Route
                path="/banneradd"
                element={<Protected Component={BannerAdd}
                  allowedRoles={"banneradd"}
                />}
              />
              <Route
                path="/editbanner/:id"
                element={<Protected Component={BannerEdit}
                  allowedRoles={"editbanner"}
                />}
              />

              {/* ******Deep Link********* */}
              <Route
                path="/deeplinklist"
                element={<Protected Component={DeepLinkList}
                  allowedRoles={"deeplinklist"}
                />}

              // ********combo offer******
              />
              <Route
                path="/comboofferlist"
                element={<Protected Component={ComboOfferList}
                  allowedRoles={"comboofferlist"}
                />}
              />
              <Route
                path="/comboofferadd"
                element={<Protected Component={ComboOfferAdd}
                  allowedRoles={"comboofferadd"}
                />}
              />
              <Route
                path="/comboofferedit/:id"
                element={<Protected Component={ComboOfferEdit}
                  allowedRoles={"comboofferedit"}
                />}
              />
              <Route
                path="/combo_offer_add_course/:id"
                element={<Protected Component={ComboOfferAddPackage}
                  allowedRoles={"combo_offer_add_course"}
                />}
              />
              <Route
                path="/attent_test_list/:id"
                element={<Protected Component={AttendTestList}
                  allowedRoles={"attent_test_list"}
                />}
              />
              <Route path="/soket" element={<CommentSocket />} />
              <Route path="/cmsList" element={<CmsList />} />
              <Route path="/cmsAdd" element={<CmsAdd />} />
              <Route path="/CmsEdit/:id" element={<CmsEdit />} />

            </>

          </Route>
          {/* <Route path="*" element={<Protected Component={RouteNotFind} />} /> */}
          <Route path="*" element={<RouteNotFind />} />

        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </>
  );
}

export default App;
// defaultChecked={(cell?.category?._id==rolePermission?.permissionId[i]?.category )?true:false}
