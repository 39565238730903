// const BASE_URL = 'https://qedapi.fluttertrends.com';
// const BASE_URL = 'https://qedapi.fluttertrends.com';

// const BASE_URL = 'http://101.53.148.75:8080';
//  const BASE_URL = 'http://localhost:8200';


// const BASE_URL = "https://qedbackend.objectsquare.in";
// const BASE_URL = "http://216.48.184.213:8100";
// const BASE_URL = "https://qednode.objectsquare.in";
const BASE_URL = "https://qualitysocket.objectsquare.in:1100";

// const BASE_URL = "http://localhost:1100";

export default BASE_URL;
 export const baseUrl = 'https://test.qualityeducation.in'