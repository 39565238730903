import React, { useState, useEffect } from "react";
// import Header from "../Layout/Header";
// import Sidebar from "../Layout/Sidebar";
import { Link, useNavigate, useParams } from "react-router-dom";
import BASE_URL from "../config/baseUrl";
import axios from "axios";
import dummyImg from "../../assets/images/dummy.png"
export default function FreeSubjectPdfEdit() {
    let { id } = useParams();
    let [img, setImg] = useState("")
    let navigate = useNavigate();
    let [formval, setFormval] = useState({
        title: "",
        image: "",
        sequence: ""
    });


    const handelOnChange = (e) => {
        if (e.target.name == "image") {
            const reader = new FileReader();
            reader.onload = () => {
                setImg(reader.result);
            };
            reader.readAsDataURL(e.target.files[0]);
            setFormval((currVal) => ({ ...currVal, image: e.target.files[0] }));
        } else {
            setFormval((currVal) => ({ ...currVal, [e.target.name]: e.target.value }));
        }
    };

    useEffect(() => {
        const apiCall = async () => {
            let response = await fetch(`${BASE_URL}/api/freePdfSubject/show/${id}`, {
                method: "GET",
                headers: {
                    "Authorization": (localStorage.getItem("token"))
                },
            });
            const result = await response.json();
            // console.log(result.data)
            setFormval({ ...result.data })
        };
        apiCall();
    }, []);

    const handelOnSubmit = (e) => {
        e.preventDefault();
        const postData = new FormData();
        postData.append("title", formval.title);
        postData.append("image", formval.image);
        postData.append("sequence", formval.sequence);

        const apiCall = async () => {
            let response = await axios(`${BASE_URL}/api/freePdfSubject/edit/${id}`, {
                method: "PUT",
                headers: {
                    "Authorization": (localStorage.getItem("token"))
                },
                data: postData,
            });
            // const result = await response.json();
            // console.log(response.data)
            // return
            if (response?.data?.status) {
                navigate("/free_subject_pdf_list");
            };
        };
        apiCall();
    };


    return (
        <>
            {/* <Header /> */}
            {/* <Sidebar /> */}
            <section className="main-sec">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="dashboard-title">
                            <h4 className="dash-head">
                                <Link to="/free_subject_pdf_list"><i className="fa-solid fa-circle-arrow-left pe-2"></i></Link>/{"  "}
                                <i className="fas fa-book me-2" />
                                Edit Subject
                            </h4>
                        </div>
                        <div className="custom-bredcump">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/free_video_pdf_subject_list">Subject Management</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Edit Subject
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="cards cstm-form">
                            <form action="" onSubmit={handelOnSubmit}>
                                <div className="row">
                                    <div className="col-lg-4 mb-4">
                                        <label htmlFor="" className="form-label">
                                            Subject Name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="title"
                                            onChange={handelOnChange}
                                            value={formval?.title}
                                        />
                                    </div>
                                    <div className="col-lg-4 mb-4">
                                        <label
                                            htmlFor=""
                                            className="form-label"
                                        >
                                            Image
                                        </label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            name="image"
                                            onChange={handelOnChange}
                                        />
                                        {<img src={img ? img : `${BASE_URL}/${formval?.image}`} onError={(e) => e.target.src = dummyImg} alt="Preview Image" className='img-50' />}
                                    </div>
                                    <div className="col-lg-4 mb-4">
                                        <label htmlFor="" className="form-label">
                                            Subject Sequence
                                        </label>
                                        <input
                                            type="number"
                                            name="sequence"
                                            onChange={handelOnChange}
                                            value={formval?.sequence}
                                            className="form-control"
                                            placeholder="Subject Sequence"
                                            min={0}
                                        />
                                    </div>
                                    <div className="col-lg-12 mb-4 text-center">
                                        <button className="thm-btn rounded-2" type="submit">
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
